import { Widget } from "@typeform/embed-react";
import { changeUserSurveyStatus } from "apis";
import { useAuthContext } from "contexts/authContext";
import { useModalsContext } from "contexts/modalContext";
import Layout from "fragments/layout/layout";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function WaitList() {
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const { toggleLoginModal } = useModalsContext();

  const handleUpdateUserStatus = async () => {
    await changeUserSurveyStatus()
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSubmit = () => {
    if(user) {
      navigate("/dashboard");
    } else {
      toggleLoginModal()
    }
  };

  useEffect(() => {
    if (user && user?.survey_done) {
      navigate("/dashboard");
    } else {
      handleUpdateUserStatus();
    }
  }, [user]);

  return (
    <Layout title="Join Waitlist">
      <div className="">
        <Widget
          onSubmit={() => handleSubmit()}
          id={"BNk01kcs"}
          style={widgetContainerStyle}
          medium="Deer Deterrent Questionnaire"
          // hidden={{ foo: "foo value", bar: "bar value" }}
          // transitiveSearchParams={["foo", "bar"]}
          // iframeProps={{ title: "Foo Bar" }}
        />
      </div>
    </Layout>
  );
}

const widgetContainerStyle = {
  // width: 500,
  height: "80vh",
  margin: "20px auto",
};

export default WaitList;
