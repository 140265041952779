import Loader from "components/loader";
import TierInfo from "components/tierInfo";
import { useAuthContext } from "contexts/authContext";
import Layout from "fragments/layout/layout";
import RouteGuard from "hoc/routeGuard";
import { BsChevronLeft } from "react-icons/bs";
import { FaCrown } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";

function AllowPort() {
  const navigate = useNavigate();
  const { userTier } = useAuthContext();

  return (
    <RouteGuard>
      <Layout title="Allow Port">
        <div className="w-full flex justify-center py-8">
          <div className="max-w-[1400px] w-full flex flex-col items-center px-6 ">
            <div className="flex max-w-[1000px] w-full">
              <div
                onClick={() => navigate("/configurations")}
                className="hover:bg-slate-200 cursor-pointer rounded-full ">
                <BsChevronLeft className="text-2xl text-black m-3" />
              </div>
            </div>
            <h1 className="text-blue md:text-4xl pb-6 text-2xl font-bold text-center">
              How to Allow Port!
            </h1>
            <TierInfo />
            <div className="flex sm:shadow-1 w-full max-w-[900px] sm:px-6 py-4 my-4 rounded-lg">
              <div className="w-full sm:p-4">
                <h2 className="text-xl mb-6 font-semibold">
                  To enable communication with Home Halo servers, please follow
                  these steps to allow port 554 (RTSP) access:
                </h2>

                <ol className="list-decimal px-4 text-lg">
                  <li>
                    Open your web browser and enter your router's IP
                    Address/Default Gateway in the address bar
                  </li>
                  <li>
                    Log in using your router's credentials (username and
                    password) to access its firmware settings.
                  </li>
                  <li>
                    Locate the port forwarding section within your router's
                    settings.
                  </li>
                  <li>Select 'Port Forwarding.</li>
                  <li>
                    Enter the IP address of your device correctly in your
                    router.
                  </li>
                  <li>
                    Input the TCP and UDP ports for the RTSP server into the
                    respective fields. The default RTSP port number is 554.
                    Then, click the 'Apply' button.
                  </li>
                  <li>That's it! You've completed the setup.</li>
                  <li>
                    To verify, you can visit{" "}
                    <a
                      className="underline text-primary text-ellipsis"
                      href="https://www.yougetsignal.com/tools/open-ports/">
                      yougetsignal.com
                    </a>
                    , enter your IP address and port 554, and check if it shows
                    as open.
                  </li>
                </ol>
              </div>
            </div>
            <div className="flex sm:shadow-1 w-full max-w-[900px] sm:px-6 py-4 my-4 rounded-lg">
              <img src="/media/allowport.png" alt="" />
            </div>
          </div>
        </div>
      </Layout>
    </RouteGuard>
  );
}

export default AllowPort;
