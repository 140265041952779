import { getCameraConfigs, getCameraStatuses } from "apis";
import { toast } from "react-toastify";
import { removeLocalData } from "utils/storage";

const { createSlice } = require("@reduxjs/toolkit");

const camerasSlice = createSlice({
  name: "cameras",
  initialState: {
    cameras: null,
    loadingCameras: false,
    camerasStatuses: [],
    loadingCamerasStatuses: true,
    unAuthError: false,
  },
  reducers: {
    setCameras(state, action) {
      state.cameras = action.payload;
    },
    setCamerasStatuses(state, action) {
      state.camerasStatuses = action.payload;
    },
    setUnAuthError(state, action) {
      state.unAuthError = action.payload;
    },
    // for loadings
    setCamerasLoading(state, action) {
      state.loadingCameras = action.payload;
    },
    setCamerasStatusesLoading(state, action) {
      state.loadingCamerasStatuses = action.payload;
    },
  },
});

export const {
  setCameras,
  setCamerasStatuses,
  setCamerasStatusesLoading,
  setCamerasLoading,
  setUnAuthError,
} = camerasSlice.actions;
export default camerasSlice.reducer;

// thunks
export function fetchCamerasStatuses() {
  return async function fetchCamerasStatusesThunk(dispatch, getState) {
    dispatch(setCamerasStatusesLoading(true));
    await getCameraStatuses()
      .then((res) => {
        // console.log(res);
        dispatch(setCamerasStatuses(res?.data?.result));
      })
      .catch((err) => {
        console.log(err);
        toast?.error("Error Fetching Cameras Previews");
      });
    dispatch(setCamerasStatusesLoading(false));
  };
}

export function fetchCameras() {
  return async function fetchCamerasStatusesThunk(dispatch, getState) {
    dispatch(setCamerasLoading(true));
    await getCameraConfigs()
      .then((res) => {
        // console.log(res);
        dispatch(setCameras(res?.data?.result));
      })
      .catch((err) => {
        console.log(err);
        toast?.error("Error Fetching Cameras");
        if (err?.response?.status == 401) {
          // dispatch(setUnAuthError(true));
          removeLocalData("__l");
          removeLocalData("user");
          window?.location?.reload();
        }
      });
    dispatch(setCamerasLoading(false));
  };
}
