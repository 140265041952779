import Button from "components/common/button";
import { useAuthContext } from "contexts/authContext";
import { useModalsContext } from "contexts/modalContext";
import Layout from "fragments/layout/layout";
import { useEffect } from "react";
import { BiErrorCircle } from "react-icons/bi";
import { Link, useNavigate } from "react-router-dom";

function SessionExpired() {
  const navigate = useNavigate();
  const { toggleLoginModal } = useModalsContext();
  const { token } = useAuthContext();

  useEffect(() => {
    if (token) {
      navigate("/");
    }
  }, [token]);

  return (
    <Layout title="Session Expired">
      <div className="h-[70vh] flex items-center justify-center">
        <div className="">
          <div className="p-6 md:mx-auto text-center flex flex-col items-center">
            <BiErrorCircle className="text-6xl text-red" />
            <div className="text-center">
              <h3 className="md:text-3xl text-base text-gray-900 font-semibold text-center">
                Session Expired!
              </h3>
              <p className="text-gray-600 my-2">
                Kindly login again to access you account.
              </p>
              <p> Have a great day! </p>
              <div className="py-10 text-center">
                <Link to="/">
                  <Button className="text-white px-6 text-lg">Home</Button>
                </Link>
                <Button
                  onClick={() => toggleLoginModal()}
                  className="text-white px-6 text-lg ml-2">
                  Login
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default SessionExpired;
