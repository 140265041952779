import { getLogicConfig } from "apis";
import { toast } from "react-toastify";

const { createSlice } = require("@reduxjs/toolkit");

const logicSlice = createSlice({
  name: "logic",
  initialState: {
    logic: null,
    loadingLogic: false,
  },
  reducers: {
    setLogic(state, action) {
      state.logic = action.payload;
    },
    // for loadings
    setLogicLoading(state, action) {
      state.loadingLogic = action.payload;
    },
  },
});

export const { setLogic, setLogicLoading } = logicSlice.actions;
export default logicSlice.reducer;

// thunks
export function fetchLogic() {
  return async function fetchLogicThunk(dispatch, getState) {
    dispatch(setLogicLoading(true));
    await getLogicConfig()
      .then((res) => {
        // console.log(res);
        dispatch(setLogic(res?.data?.result));
      })
      .catch((err) => {
        console.log(err);
        toast?.error("Error Fetching Logic Configs");
      });
    dispatch(setLogicLoading(false));
  };
}
