import { getSprinklerConfigs, getSprinklerStatuses } from "apis";
import { toast } from "react-toastify";

const { createSlice } = require("@reduxjs/toolkit");

const sprinklersSlice = createSlice({
  name: "sprinklers",
  initialState: {
    sprinklers: null,
    loadingSprinklers: false,
    sprinklersStatuses: null,
    loadingSprinklersStatuses: false,
  },
  reducers: {
    setSprinklers(state, action) {
      state.sprinklers = action.payload;
    },
    setSprinklersStatuses(state, action) {
      state.sprinklersStatuses = action.payload;
    },
    // for loadings
    setSprinklersLoading(state, action) {
      state.loadingSprinklers = action.payload;
    },
    setSprinklersStatusesLoading(state, action) {
      state.loadingSprinklersStatuses = action.payload;
    },
  },
});

export const {
  setSprinklers,
  setSprinklersStatuses,
  setSprinklersLoading,
  setSprinklersStatusesLoading,
} = sprinklersSlice.actions;
export default sprinklersSlice.reducer;

// thunks
export function fetchSprinkersStatuses() {
  return async function fetchSprinkersStatusesThunk(dispatch, getState) {
    dispatch(setSprinklersStatusesLoading(true));
    await getSprinklerStatuses()
      .then((res) => {
        // console.log(res);
        dispatch(setSprinklersStatuses(res?.data?.result));
      })
      .catch((err) => {
        console.log(err);
        toast?.error("Error Fetching Sprinklers Statuses");
      });
    dispatch(setSprinklersStatusesLoading(false));
  };
}

export function fetchSprinkers() {
  return async function fetchSprinkersThunk(dispatch, getState) {
    dispatch(setSprinklersLoading(true));
    await getSprinklerConfigs()
      .then((res) => {
        // console.log(res);
        dispatch(setSprinklers(res?.data?.result));
      })
      .catch((err) => {
        console.log(err);
        toast?.error("Error Fetching Sprinklers");
      });
    dispatch(setSprinklersLoading(false));
  };
}
