import { Testimonials } from "components/testimonials";
import Layout from "fragments/layout/layout";
import Hero from "./components/hero";
import { PrimaryFeatures } from "./components/primaryFeatures";
import HowItWorks from "./components/howItWorks";

export default function Home() {
  return (
    <Layout>
      <div className="w-full flex justify-center  bg-wite">
        <div className="flex flex-col gap-14 w-full">
          <Hero />
          <PrimaryFeatures />
          {/* <HowItWorks /> */}
          <Testimonials />
        </div>
      </div>
    </Layout>
  );
}
