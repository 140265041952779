import { addCameraConfig } from "apis";
import Button, { IconButton } from "components/common/button";
import { TextField } from "components/common/fields";
import Loader from "components/loader";
import TierInfo from "components/tierInfo";
import { useAuthContext } from "contexts/authContext";
import Layout from "fragments/layout/layout";
import RouteGuard from "hoc/routeGuard";
import { useEffect, useState } from "react";
import { BiError } from "react-icons/bi";
import { BsChevronLeft } from "react-icons/bs";
import { FaCrown } from "react-icons/fa";
import { GrAddCircle } from "react-icons/gr";
import { RxCross1, RxReload } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  fetchCameras,
  fetchCamerasStatuses,
  setCameras,
  setCamerasStatuses,
} from "store/camerasSlice";

function Camera() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [camerasConfigs, setCamerasConfigs] = useState();
  const [camerasPreview, setCamerasPreview] = useState([]);
  const [loading, setLoading] = useState();
  const { userTier } = useAuthContext();
  const { cameras, camerasStatuses, loadingCamerasStatuses, loadingCameras } =
    useSelector((state) => state.cameras);

  const handleSubmitCamera = async () => {
    setLoading(true);
    await addCameraConfig({
      configs: camerasConfigs,
    })
      .then((res) => {
        toast.success("Successfully Update Camera Config");
        // getCameraPreviews();
        dispatch(fetchCamerasStatuses());
        dispatch(setCameras(camerasConfigs));
      })
      .catch((err) => {
        console.log(err);
        toast.error(
          err?.response?.data?.message || "Error Updating Camera Config"
        );
      });
    setLoading(false);
  };

  const handleChange = ({ fieldName, index, value }) => {
    var _cameras = [...camerasConfigs];

    let objectToEdit = { ..._cameras[index] };
    objectToEdit[fieldName] = value;
    _cameras[index] = objectToEdit;

    setCamerasConfigs([..._cameras]);
  };

  const handleRemoveCamera = (index) => {
    let _cameras = [...camerasConfigs];
    let _camerasPreviews = [...camerasPreview];
    _cameras.splice(index, 1);
    _camerasPreviews.splice(index, 1);

    setCamerasConfigs([..._cameras]);
    setCamerasPreview([..._camerasPreviews]);
    toast.success("Successfully Removed Camera Config");
  };

  const handleRefreshCameras = async () => {
    dispatch(fetchCameras());
    dispatch(fetchCamerasStatuses());
    dispatch(setCameras(null));
    dispatch(setCamerasStatuses(null));
  };

  useEffect(() => {
    setCamerasConfigs(cameras);
    setCamerasPreview(camerasStatuses);
  }, [cameras, camerasStatuses]);

  // console.log("cams", camerasConfigs);
  // console.log("camStatuses", camerasPreview);
  // console.log("loadingCamerasStatuses", loadingCamerasStatuses);

  return (
    <RouteGuard>
      <Layout title="Camera">
        <div className="w-full flex justify-center py-8">
          <div className="max-w-[1400px] w-full flex flex-col items-center px-6 ">
            <div className="flex max-w-[1000px] w-full">
              <div
                onClick={() => navigate("/configurations")}
                className="hover:bg-slate-200 cursor-pointer rounded-full ">
                <BsChevronLeft className="text-2xl text-black m-3" />
              </div>
            </div>
            <h1 className="text-blue md:text-4xl pb-6 text-2xl font-bold text-center">
              Cameras
            </h1>
            <TierInfo />
            <div className="flex w-full justify-end max-w-[900px]">
              <Button
                onClick={() => handleRefreshCameras()}
                loading={loadingCameras}
                className="text-primary flex items-center gap-2 bg-sky-100">
                <RxReload className="text-lg" />
                Refresh
              </Button>
            </div>
            {camerasConfigs ? (
              <>
                {camerasConfigs?.length > 0 ? (
                  <>
                    {camerasConfigs.map((item, index) => (
                      <div
                        key={index}
                        className="flex shadow-1 w-full max-w-[900px] px-6 py-4 my-4 rounded-lg">
                        <div className="grid sm:grid-cols-2 grid-cols-1 gap-4 justify-between w-full">
                          <div className="flex flex-col gap-4">
                            <TextField
                              tooltipText="Example: GarageCam"
                              value={item?.name}
                              label="Camera Name"
                              onChange={(e) =>
                                handleChange({
                                  fieldName: "name",
                                  index: index,
                                  value: e?.target?.value,
                                })
                              }
                            />
                            <TextField
                              tooltipText="Format: rtsp://username:password@ipaddress"
                              value={item?.rtsp_url}
                              label="Camera Feed URL"
                              onChange={(e) =>
                                handleChange({
                                  fieldName: "rtsp_url",
                                  index: index,
                                  value: e?.target?.value,
                                })
                              }
                            />
                          </div>

                          <div>
                            <div
                              className="float-right"
                              onClick={() => handleRemoveCamera(index)}>
                              <RxCross1 className="text-xl cursor-pointer text-red" />
                            </div>
                            <div>
                              {camerasPreview?.length > 0 ? (
                                <>
                                  {camerasPreview?.find(
                                    (value) => value?.rtsp_url == item?.rtsp_url
                                  ) ? (
                                    <div>
                                      <p className="block text-sm font-medium leading-6">
                                        Live Preview
                                      </p>
                                      <div className="sm:max-w-250px sm:w-full sm:flex sm:justify-end">
                                        <img
                                          className="rounded-lg w-full"
                                          src={`data:image/png;base64,${
                                            camerasPreview?.find(
                                              (value) =>
                                                value?.rtsp_url ==
                                                item?.rtsp_url
                                            )?.thumbnail
                                          }`}
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                  ) : (
                                    <>{loadingCamerasStatuses && <Loader />}</>
                                  )}
                                </>
                              ) : (
                                <Loader />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                ) : (
                  <div className="text-center flex flex-col items-center w-full my-4 opacity-60">
                    <BiError style={{ fontSize: "3rem", opacity: "0.4" }} />
                    No Camera Configs found.
                  </div>
                )}

                <div className="w-full flex justify-between max-w-[900px]">
                  <GrAddCircle
                    className="text-primary opacity-70 text-2xl w-12 h-12 font-bold hover:bg-lightgray rounded-full cursor-pointer p-2"
                    onClick={() => {
                      setCamerasPreview([
                        ...camerasPreview,
                        { name: "loading", rtsp_url: "loading", thumbnail: "" },
                      ]);
                      setCamerasConfigs([
                        ...camerasConfigs,
                        { name: "", rtsp_url: "" },
                      ]);
                    }}
                  />
                  <Button
                    loading={loading}
                    onClick={() => handleSubmitCamera()}
                    className="bg-blue text-white w-fit float-right">
                    Save
                  </Button>
                </div>
              </>
            ) : (
              <Loader />
            )}
          </div>
        </div>
      </Layout>
    </RouteGuard>
  );
}

export default Camera;
