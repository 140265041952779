import { configureStore } from "@reduxjs/toolkit";
import camerasSlice from "./camerasSlice";
import sprinklersSlice from "./sprinklersSlice";
import logicSlice from "./logicSlice";
import userDetailSlice from "./userDetailSlice";

const store = configureStore({
  reducer: {
    cameras: camerasSlice,
    sprinklers: sprinklersSlice,
    logic: logicSlice,
    userDetails: userDetailSlice,
  },
});

export default store;
