import { Avatar } from "components/common/avatar";
import Loader from "components/loader";
import { useAuthContext } from "contexts/authContext";
import React from "react";

function General() {
  const { user } = useAuthContext();

  return (
    <>
      {user ? (
        <div>
          <h2 className="text-base font-semibold leading-7 text-gray-900">
            Profile
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-500">
            This information is provided by your email account provider.
          </p>

          <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
            <div className="pt-6 sm:flex">
              <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                Profile Image
              </dt>
              <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                <img
                  className={`inline-block h-16 w-16 rounded-full`}
                  src={user?.photoURL}
                  alt=""
                />
                {/* <button
              type="button"
              className="font-semibold text-indigo-600 hover:text-indigo-500">
              Update
            </button> */}
              </dd>
            </div>
            <div className="pt-6 sm:flex">
              <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                Full name
              </dt>
              <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                <div className="text-gray-900">{user?.username}</div>
                {/* <button
              type="button"
              className="font-semibold text-indigo-600 hover:text-indigo-500">
              Update
            </button> */}
              </dd>
            </div>
            <div className="pt-6 sm:flex">
              <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                Email address
              </dt>
              <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                <div className="text-gray-900">{user?.email}</div>
                {/* <button
              type="button"
              className="font-semibold text-indigo-600 hover:text-indigo-500">
              Update
            </button> */}
              </dd>
            </div>
            <div className="pt-6 sm:flex">
              <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                LoggedIn using
              </dt>
              <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                <div className="text-gray-900">{user?.type?.toUpperCase()}</div>
                {/* <button
                  type="button"
                  className="font-semibold text-indigo-600 hover:text-indigo-500">
                  Update
                </button> */}
              </dd>
            </div>
          </dl>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
}

export default General;
