import { startTrial } from "apis";
import Button from "components/common/button";
import Loader from "components/loader";
import { useAuthContext } from "contexts/authContext";
import Layout from "fragments/layout/layout";
import RouteGuard from "hoc/routeGuard";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

function PaymentSuccess() {
  const { token } = useAuthContext();
  const [trialStarted, setTrialStarted] = useState(false);

  const handleStartTrial = async () => {
    await startTrial()
      .then((res) => {
        console.log(res);
        toast?.success("Congratulations! Your Trial is started.");
        setTrialStarted(true);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.response?.data?.message || "Something went wrong.");
      });
  };

  useEffect(() => {
    if (token) {
      handleStartTrial();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return (
    <RouteGuard>
      <Layout title="Payment Successful">
        {trialStarted ? (
          <div className="h-[70vh] flex items-center justify-center">
            <div className="">
              <div className="p-6 md:mx-auto">
                <svg
                  viewBox="0 0 24 24"
                  className="text-green-600 w-16 h-16 mx-auto my-6">
                  <path
                    fill="currentColor"
                    d="M12,0A12,12,0,1,0,24,12,12.014,12.014,0,0,0,12,0Zm6.927,8.2-6.845,9.289a1.011,1.011,0,0,1-1.43.188L5.764,13.769a1,1,0,1,1,1.25-1.562l4.076,3.261,6.227-8.451A1,1,0,1,1,18.927,8.2Z"></path>
                </svg>
                <div className="text-center">
                  <h3 className="md:text-2xl text-base text-gray-900 font-semibold text-center">
                    Payment Done!
                  </h3>
                  <p className="text-gray-600 my-2">
                    Thank you for completing your secure online payment.
                  </p>
                  <p> Have a great day! </p>
                  <div className="py-10 text-center">
                    <Link to="/">
                      <Button className="text-white px-6 text-lg">Home</Button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <Loader />
        )}
      </Layout>
    </RouteGuard>
  );
}

export default PaymentSuccess;
