export const tiers = [
  {
    name: "Basic",
    backendName: 'basic',
    id: "tier-basic",
    href: "#",
    price: { monthly: "$10", annually: "$120" },
    description: "Get Started with Essential Protection",
    features: [
      "Includes 14-day trial",
      "Unlimited person or deer dectection",
      "Support for 1 camera",
      "Support for 1 sprinkler controller",
      "Detection every 10 seconds",
      "Basic analytics",
      "Email Notification",
    ],
    mostPopular: false,
  },
  {
    name: "Premium",
    backendName: 'premium',
    id: "tier-pro",
    href: "#",
    price: { monthly: "$20", annually: "$240" },
    description: "Comprehensive Defense for Your Yard",
    features: [
      "Includes everything in Basic plan",
      "Support for 4 cameras",
      "Support for unlimited sprinkler zones",
      "Rapid detection, detection every 1 second",
      "Dedicated E-mail support",
    ],
    mostPopular: true,
  },
  {
    name: "Unlimited",
    backendName: 'unlimited',
    id: "tier-unlimited",
    href: "#",
    price: { monthly: "Call for Pricing", annually: "$0" },
    description: "Tailor-Made Backyard Security Solutions",
    features: [
      "Inlcudes everything in Premium plan",
      "Customize object detection and actions, tailored to you",
      "Unlimited cameras",
      "Unlimited sprinkler zones",
      "Guaranteed 1-hour dedicated support response time",
    ],
    mostPopular: false,
  },
];