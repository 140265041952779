import { Helmet } from "react-helmet";
import { Header } from "fragments/header";
import { Footer } from "fragments/footer";

export default function Layout({ children, title }) {
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Header />
      <div className="min-h-[50vh] mt-[32px]">{children}</div>
      <Footer />
    </>
  );
}

Layout.defaultProps = {
  title:
    "Home Halo | Deer Repellent | Deer Repellent Plants | Keep Animals Out of Garden",
  description:
    "Discover Home Halo's effective deer repellent devices, deer-resistant shrubs/plants, and garden animal barriers to keep your garden safe and deer-free.",
  keywords: "noindex, nofollow",
};
