import { addSprinklerConfig } from "apis";
import Button from "components/common/button";
import { TextField } from "components/common/fields";
import Select from "components/common/select";
import Loader from "components/loader";
import TierInfo from "components/tierInfo";
import { useAuthContext } from "contexts/authContext";
import Layout from "fragments/layout/layout";
import RouteGuard from "hoc/routeGuard";
import { useEffect, useState } from "react";
import { BiError } from "react-icons/bi";
import { BsChevronLeft } from "react-icons/bs";
import { FaCrown } from "react-icons/fa";
import { GrAddCircle } from "react-icons/gr";
import { RxCross1, RxReload } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  fetchSprinkers,
  fetchSprinkersStatuses,
  setSprinklers,
  setSprinklersStatuses,
} from "store/sprinklersSlice";

function Sprinkle() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userTier } = useAuthContext();
  const [sprinklersConfigs, setSprinklersConfigs] = useState();
  const [loading, setLoading] = useState();

  const { sprinklers, loadingSprinklers } = useSelector(
    (state) => state.sprinklers
  );

  const handleSubmitSprinkler = async () => {
    setLoading(true);
    await addSprinklerConfig({
      configs: sprinklersConfigs,
    })
      .then((res) => {
        // console.log(res);
        dispatch(fetchSprinkersStatuses());
        dispatch(setSprinklers(sprinklersConfigs));
        toast.success("Successfully Update Sprinkler Config");
      })
      .catch((err) => {
        console.log(err);
        toast.error(
          err?.response?.data?.message || "Error Updating Sprinkler Config"
        );
      });
    setLoading(false);
  };

  const handleRefreshSprinklers = async () => {
    dispatch(fetchSprinkers());
    dispatch(fetchSprinkersStatuses());
    dispatch(setSprinklers(null));
    dispatch(setSprinklersStatuses(null));
  };

  const handleChange = ({ fieldName, index, value }) => {
    let _sprinklers = [...sprinklersConfigs];

    let objectToEdit = _sprinklers[index];
    objectToEdit[fieldName] = value;
    _sprinklers[index] = objectToEdit;

    setSprinklersConfigs([..._sprinklers]);
  };

  const handleRemoveSprinkler = (index) => {
    let _sprinklers = [...sprinklersConfigs];
    _sprinklers.splice(index, 1);

    setSprinklersConfigs([..._sprinklers]);
    toast.success("Successfully Removed Sprinkler Config");
  };

  useEffect(() => {
    setSprinklersConfigs(sprinklers);
  }, [sprinklers]);

  return (
    <RouteGuard>
      <Layout title="Sprinklers">
        <div className="w-full flex justify-center py-8">
          <div className="max-w-[1400px] w-full flex flex-col items-center px-6 ">
            <div className="flex max-w-[1000px] w-full">
              <div
                onClick={() => navigate("/configurations")}
                className="hover:bg-slate-200 cursor-pointer rounded-full ">
                <BsChevronLeft className="text-2xl text-black m-3" />
              </div>
            </div>
            <h1 className="text-blue md:text-4xl pb-6 text-2xl font-bold text-center">
              Sprinklers
            </h1>
            <TierInfo />
            <div className="flex w-full justify-end max-w-[900px]">
              <Button
                onClick={() => handleRefreshSprinklers()}
                loading={loadingSprinklers}
                className="text-primary flex items-center gap-2 bg-sky-100">
                <RxReload className="text-lg" />
                Refresh
              </Button>
            </div>
            {sprinklersConfigs ? (
              <>
                {sprinklersConfigs?.length > 0 ? (
                  <>
                    {sprinklersConfigs.map((item, index) => (
                      <div className="flex shadow-1 w-full max-w-[900px] px-6 py-4 my-4 rounded-lg">
                        <div className="grid sm:grid-cols-1 grid-cols-1 gap-4 justify-between w-full">
                          <div className="flex flex-col gap-4">
                            <div className="w-full">
                              <div
                                className="float-right"
                                onClick={() => handleRemoveSprinkler(index)}>
                                <RxCross1 className="text-xl cursor-pointer" />
                              </div>
                              <Select
                                tooltipText="Lorem, ipsum dolor sit amet consectetur adipisicing elit."
                                value={item?.type}
                                label="Sprinkler Type"
                                options={["rachio"]}
                                onChange={(e) => {
                                  handleChange({
                                    fieldName: "type",
                                    index: index,
                                    value: e,
                                  });
                                }}
                              />
                            </div>
                            <div className="w-full">
                              <TextField
                                value={item?.url}
                                label="Sprinkler URL"
                                onChange={(e) =>
                                  handleChange({
                                    fieldName: "url",
                                    index: index,
                                    value: e?.target?.value,
                                  })
                                }
                              />
                            </div>
                            <div className="w-full">
                              <TextField
                                value={item?.bearer_token}
                                label="Sprinkler Bearer Token"
                                onChange={(e) =>
                                  handleChange({
                                    fieldName: "bearer_token",
                                    index: index,
                                    value: e?.target?.value,
                                  })
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                ) : (
                  <div className="text-center flex flex-col items-center w-full my-4 opacity-60">
                    <BiError style={{ fontSize: "3rem", opacity: "0.4" }} />
                    No Sprinkler Configs found.
                  </div>
                )}
                <div className="w-full flex justify-between items-center max-w-[900px]">
                  <GrAddCircle
                    className="text-primary opacity-70 text-2xl w-12 h-12 font-bold hover:bg-lightgray rounded-full cursor-pointer p-2"
                    onClick={() =>
                      setSprinklersConfigs([
                        ...sprinklersConfigs,
                        {
                          type: "",
                          url: "",
                          bearer_token: "",
                        },
                      ])
                    }
                  />
                  <Button
                    loading={loading}
                    onClick={() => handleSubmitSprinkler()}
                    className="text-white w-fit float-right ">
                    Save
                  </Button>
                </div>
              </>
            ) : (
              <Loader />
            )}
          </div>
        </div>
      </Layout>
    </RouteGuard>
  );
}

export default Sprinkle;
