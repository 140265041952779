import Loader from "components/loader";
import React from "react";

export default function Button({ children, loading, onClick, className }) {
  return (
    <button
      className={`font-bold py-2 px-4 rounded-full
                  focus:outline-none focus:shadow-outline bg-blue-600
                  group hover:shadow-xl transform hover:-translate-y-1 transition duration-300 ease-in-out ${
                    loading ? "cursor-not-allowed" : "cursor-pointer"
                  } ${className} ${loading && "bg-gray-400"}`}
      onClick={onClick}
      disabled={loading}>
      {loading ? "Loading..." : children || "Button"}
    </button>
  );
}

export function IconButton({ children, loading, onClick, className }) {
  return (
    <button
      className={`font-bold p-2 rounded-full
                  focus:outline-none focus:shadow-outline bg-blue-100
                  group hover:shadow-xl transform hover:-translate-y-1 transition duration-300 ease-in-out ${
                    loading ? "cursor-not-allowed" : "cursor-pointer"
                  } ${className} ${loading && "bg-gray-100 p-1"}`}
      onClick={onClick}
      disabled={loading}>
      {loading ? <Loader width='25px' height="25px" /> : children || "Button"}
    </button>
  );
}
