import { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { Popover, Transition } from "@headlessui/react";
import clsx from "clsx";
import { Container } from "components/common/container";
import { useAuthContext } from "contexts/authContext";
import { Avatar } from "components/common/avatar";
import { DropDown } from "components/common/dropdown";
import { removeLocalData } from "utils/storage";
import Button from "components/common/button";
import { Logo } from "components/common/logo";
import { NavLink } from "components/common/NavLink";
import { capitalizeFirstLetter } from "utils";
import Login from "components/modals/login";
import { useModalsContext } from "contexts/modalContext";
import { FiSettings } from "react-icons/fi";
import { GrConfigure } from "react-icons/gr";

const publicLinks = [
  { name: "Plans", href: "/plans" },
  { name: "FAQ", href: "/faqs" },
  { name: "Contact us", href: "/contact" },
];

const privateLinks = [{ name: "Dashboard", href: "/dashboard" }];

export function Header() {
  const { user, token, handleLogout } = useAuthContext();
  const { toggleLoginModal } = useModalsContext();

  // const handleLogout = () => {
  //   removeLocalData("__l");
  //   removeLocalData("user");

  //   window?.location?.reload();
  // };

  return (
    <header className="py-10">
      <Container>
        <nav className="relative z-50 flex justify-between">
          <div className="flex items-center md:gap-x-12">
            <Link to="/" aria-label="Home">
              {/* <Logo className="h-10 w-auto" /> */}
              <img src="/media/logo-main.png" alt="" className="w-[200px]" />
            </Link>
            <div className="hidden lg:flex lg:gap-x-6">
              {publicLinks.map((item) => (
                <NavLink key={item.name} to={item.href}>
                  {item.name}
                </NavLink>
              ))}
            </div>
          </div>
          <div className="flex items-center gap-x-5 md:gap-x-8">
            {token &&
              privateLinks.map((item) => (
                <NavLink key={item.name} to={item.href}>
                  {item.name}
                </NavLink>
              ))}
            <div className="hidden lg:block">
              {token ? (
                <>
                  <DropDown
                    bodyClassName="max-w-[200px] mt-2 whitespace-nowrap overflow-hidden text-ellipsis"
                    label={
                      <Button className="bg-blue text-white max-w-[200px] whitespace-nowrap  text-ellipsis">
                        Welcome{" "}
                        {capitalizeFirstLetter(user?.username.split(" ")[0])}
                      </Button>
                    }>
                    <div className="w-full flex flex-col">
                      <div className="flex items-center gap-2 hover:bg-lightgray cursor-pointer rounded-md p-2">
                        <Avatar className="h-8 w-8" src={user?.photoURL} />
                        <p className="text-lg font-bold">
                          {capitalizeFirstLetter(user?.username.split(" ")[0])}
                        </p>
                      </div>
                      <hr className="m-2 border-slate-300/40" />
                      <Link to="/settings?tab=account" className="mb-2">
                        <div className="flex items-center gap-2 hover:bg-lightgray cursor-pointer rounded-md p-2">
                          <FiSettings />
                          Settings
                        </div>
                      </Link>
                      <Link to="/configurations" className="mb-2">
                        <div className="flex items-center gap-2 hover:bg-lightgray cursor-pointer rounded-md p-2">
                          <GrConfigure />
                          Configuration
                        </div>
                      </Link>
                      <Button
                        onClick={() => handleLogout()}
                        className="bg-blue text-white w-full z-[200]">
                        Log out
                      </Button>
                    </div>
                  </DropDown>
                </>
              ) : (
                // <Link to="/login">
                <Button
                  onClick={() => toggleLoginModal()}
                  className="bg-blue text-white">
                  Sign in
                </Button>
                // </Link>
              )}
            </div>

            <div className="-mr-1 lg:hidden">
              <Popover>
                <Popover.Button
                  className="relative z-10 flex h-8 w-8 items-center justify-center ui-not-focus-visible:outline-none"
                  aria-label="Toggle Navigation">
                  {({ open }) => <MobileNavIcon open={open} />}
                </Popover.Button>
                <Transition.Root>
                  <Transition.Child
                    as={Fragment}
                    enter="duration-150 ease-out"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="duration-150 ease-in"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0">
                    <Popover.Overlay className="fixed inset-0 bg-slate-300/50" />
                  </Transition.Child>
                  <Transition.Child
                    as={Fragment}
                    enter="duration-150 ease-out"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="duration-100 ease-in"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95">
                    <Popover.Panel
                      as="div"
                      className="absolute inset-x-0 top-full mt-4 flex origin-top flex-col rounded-2xl bg-white p-4 text-lg tracking-tight text-slate-900 shadow-xl ring-1 ring-slate-900/5">
                      {publicLinks.map((item) => (
                        <MobileNavLink key={item.name} to={item.href}>
                          {item.name}
                        </MobileNavLink>
                      ))}
                      {token &&
                        privateLinks.map((item) => (
                          <MobileNavLink key={item.name} to={item.href}>
                            {item.name}
                          </MobileNavLink>
                        ))}
                      <hr className="m-2 border-slate-300/40" />
                      {!token ? (
                        <>
                          {/* <Link to="/login"> */}
                          <Button
                            onClick={() => toggleLoginModal()}
                            className="w-full bg-blue text-white">
                            Log in
                          </Button>
                          {/* </Link> */}
                        </>
                      ) : (
                        <>
                          <div className="flex mb-4 items-center gap-2 hover:bg-lightgray cursor-pointer rounded-md p-2">
                            <Avatar className="h-8 w-8" src={user?.photoURL} />
                            <p className="text-lg font-bold">
                              {capitalizeFirstLetter(
                                user?.username.split(" ")[0]
                              )}
                            </p>
                          </div>
                          <Link to="/settings?tab=account">
                            <div className="flex mb-4 items-center gap-2 hover:bg-lightgray cursor-pointer rounded-md p-2">
                              <FiSettings />
                              Settings
                            </div>
                          </Link>
                          <Link to="/configurations">
                            <div className="flex mb-4 items-center gap-2 hover:bg-lightgray cursor-pointer rounded-md p-2">
                              <GrConfigure />
                              Configuration
                            </div>
                          </Link>
                          <Button
                            onClick={() => handleLogout()}
                            className="bg-blue text-white w-full z-[200]">
                            Log out
                          </Button>
                        </>
                      )}
                    </Popover.Panel>
                  </Transition.Child>
                </Transition.Root>
              </Popover>
            </div>
          </div>
        </nav>
      </Container>
    </header>
  );
}

function MobileNavIcon({ open }) {
  return (
    <svg
      aria-hidden="true"
      className="h-3.5 w-3.5 overflow-visible stroke-slate-700"
      fill="none"
      strokeWidth={2}
      strokeLinecap="round">
      <path
        d="M0 1H14M0 7H14M0 13H14"
        className={clsx(
          "origin-center transition",
          open && "scale-90 opacity-0"
        )}
      />
      <path
        d="M2 2L12 12M12 2L2 12"
        className={clsx(
          "origin-center transition",
          !open && "scale-90 opacity-0"
        )}
      />
    </svg>
  );
}

function MobileNavLink({ to, children }) {
  return (
    <Popover.Button as={Link} to={to} className="block w-full p-2">
      {children}
    </Popover.Button>
  );
}
