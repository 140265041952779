import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getLocalData } from "utils/storage";

function RouteGuard({ children }) {
  const navigate = useNavigate();
  const _token = getLocalData("__l");

  useEffect(() => {
    if (!_token) {
      navigate("/session-expired");
    } else {
      // navigate('/dashboard')
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_token]);

  return <>{children}</>;
}

export default RouteGuard;
