export const stripTestLinkForBasicPlan =
  "https://buy.stripe.com/test_4gwdUaeaLg7Fg48bIJ?prefilled_email=";
export const stripTestLinkForPremiumPlan =
  "https://buy.stripe.com/test_4gwdUaeaLg7Fg48bIJ?prefilled_email=";

export const stripLinkForBasicPlanWithTrial =
  "https://buy.stripe.com/28oaFo4FaaUa4rCcMO?prefilled_email=";

export const stripLinkForBasicPlanWithoutTrial =
  "https://buy.stripe.com/28o6p82x25zQbU45kl?prefilled_email=";

export const stripLinkForPremiumPlanWithTrial =
  "https://buy.stripe.com/eVa9BkgnS7HY5vGcMM?prefilled_email=";

export const stripLinkForPremiumPlanWithoutTrial =
  "https://buy.stripe.com/14k28S8Vq9Q60bm147?prefilled_email=";
