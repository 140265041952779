import Loader from "components/loader";
import { useAuthContext } from "contexts/authContext";
import React from "react";
import { FaCrown } from "react-icons/fa";
import { Link } from "react-router-dom";

function TierInfo() {
  const { userTier } = useAuthContext();

  return (
    <>
      {userTier ? (
        <div className="bg-blue-100 py-2 px-4 flex items-center gap-2 rounded-xl">
          <FaCrown className="text-primary" />
          {userTier?.tier == "free" || userTier?.tier == "basic" ? (
            <>
              <p className="text-primary">{userTier?.tier?.toUpperCase()}</p>
              <Link to="/plans" className="underline">
                upgrade to Premium
              </Link>
            </>
          ) : (
            <p className="text-primary">{userTier?.tier?.toUpperCase()}</p>
          )}
        </div>
      ) : (
        <Loader height="20px" width="20px" />
      )}
    </>
  );
}

export default TierInfo;
