import { TextField } from "components/common/fields";
import { useAuthContext } from "contexts/authContext";
import { useModalsContext } from "contexts/modalContext";
import { useEffect } from "react";
import { BiError } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { fetchCardDetails } from "store/userDetailSlice";
import Loader from "../../../components/loader/index";
import { Link } from "react-router-dom";

function BankDetails() {
  const { toggleUpdateCardDetailsModal } = useModalsContext();
  const dispatch = useDispatch();
  const { token, userTier } = useAuthContext();
  const { card } = useSelector((state) => state.userDetails);

  useEffect(() => {
    if (token) {
      dispatch(fetchCardDetails());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return (
    <div>
      <h2 className="text-xl font-semibold leading-7 text-gray-900">
        Subscription
      </h2>
      <p className="mt-1 text-sm leading-6 text-gray-500">
        Information about you current subscription
      </p>

      {userTier ? (
        <>
          <div className="mt-6 divide-gray-100 pb-8 border-b border-gray-200 text-sm leading-6">
            <div className="grid grid-cols-2 my-2 max-w-[400px] items-center">
              <h2 className="text-base font-semibold leading-7 text-gray-900">
                Plan Tier:
              </h2>
              <p>
                {userTier?.tier?.toUpperCase()}{" "}
                {userTier?.tier != "premium" && (
                  <Link className="underline text-primary" to="/plans">
                    upgrade your plan
                  </Link>
                )}
              </p>
            </div>
            {userTier?.days > 0 && (
              <div className="grid grid-cols-2 max-w-[400px] items-center">
                <h2 className="text-base font-semibold leading-7 text-gray-900">
                  Trial Remaining:
                </h2>
                <p>{userTier?.days} Days</p>
              </div>
            )}
          </div>
        </>
      ) : (
        <Loader />
      )}

      <h2 className="text-xl mt-6 font-semibold leading-7 text-gray-900">
        Bank accounts
      </h2>
      <p className="mt-1 text-sm leading-6 text-gray-500">
        Connect bank accounts to your account.
      </p>

      {card ? (
        <>
          {card != "no details" ? (
            <>
              <div className="mt-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
                <div className="flex gap-4 md:flex-row flex-col my-8">
                  <TextField
                    label="Card Number"
                    disabled
                    value={card?.number}
                    className="w-full md:w-[50%] opacity-60"
                  />
                  <div className="flex gap-4 w-full">
                    <TextField
                      label="Card Expiry"
                      disabled
                      className="w-full md:w-fit opacity-60"
                      value={`${card?.exp_month}/${card?.exp_year}`}
                    />
                    <TextField
                      label="CVC"
                      disabled
                      value="***"
                      className="w-full md:w-fit opacity-60"
                    />
                  </div>
                </div>
              </div>
              <div className="flex sm:flex-row justify-start text-left flex-col border-t gap-4 sm:divide-x-2 border-gray-100 pt-6">
                {/* <button
                  onClick={() => toggleDeleteCardDetailsModal()}
                  type="button"
                  className="text-sm font-semibold leading-6 w-fit text-red hover:opacity-80">
                  <span aria-hidden="true"></span> Remove Card Details
                </button> */}
                <button
                  onClick={() => toggleUpdateCardDetailsModal()}
                  type="button"
                  className="text-sm pl- sm:pl-4 font-semibold w-fit leading-6 text-primary hover:opacity-80">
                  <span aria-hidden="true"></span> Update Card Details
                </button>
              </div>
            </>
          ) : (
            <div>
              <div className="text-center flex flex-col items-center w-full my-4 opacity-60">
                <BiError style={{ fontSize: "3rem", opacity: "0.4" }} />
                No Card Details found.
              </div>
              {/* <div className="flex border-t border-gray-100 pt-6">
                <button
                  onClick={() => toggleAddCardDetailsModal()}
                  type="button"
                  className="text-sm font-semibold leading-6 text-primary hover:opacity-80">
                  <span aria-hidden="true"></span> Add Card Details
                </button>
              </div> */}
            </div>
          )}
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
}

export default BankDetails;
