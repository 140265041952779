import { useAuthContext } from "contexts/authContext";
import React from "react";

export function Avatar({ src, className, alt }) {
  const { user } = useAuthContext();
  return (
    <>
      <img
        className={`inline-block h-6 w-6 rounded-full ${className}`}
        src={
          src || `https://eu.ui-avatars.com/api/?name=${user?.username}&size=250`
        }
        alt=""
      />
    </>
  );
}
