import { Listbox, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { BsCheck2, BsChevronExpand, BsInfoCircle } from "react-icons/bs";
import Tooltip from "../tooltip";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Select({
  label,
  options,
  onChange,
  value,
  className,
  tooltipText,
  error,
}) {
  return (
    <div className={`w-full ${className}`}>
      <Listbox value={value} onChange={onChange}>
        {({ open }) => (
          <>
            {label && (
              <Listbox.Label className="flex items-center gap-1 text-sm font-medium leading-6 text-gray-900">
                {label}
                {tooltipText && (
                  <Tooltip content={tooltipText}>
                    <BsInfoCircle
                      className={`cursor-pointer opacity-40 ${
                        error && "text-red"
                      }`}
                    />
                  </Tooltip>
                )}
              </Listbox.Label>
            )}
            <div className="relative">
              <Listbox.Button className="relative h-10 w-full cursor-pointer rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-200 focus:outline-none focus:ring-1 focus:ring-primary sm:text-sm sm:leading-6">
                <span className="block truncate">{value}</span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <BsChevronExpand
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0">
                <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {options?.map((option, index) => (
                    <Listbox.Option
                      key={index}
                      className={({ active }) =>
                        classNames(
                          active
                            ? "bg-blue-100 text-teal"
                            : "text-gray-900 bg-white",
                          "relative cursor-default select-none"
                        )
                      }
                      value={option}>
                      {({ selected, active }) => (
                        <>
                          <span
                            className={classNames(
                              selected
                                ? "font-semibold bg-blue-200"
                                : "font-normal bg-white",
                              "block truncate  py-1 pl-3 pr-9"
                            )}>
                            {option}
                          </span>

                          {selected ? (
                            <span
                              className={classNames(
                                active ? "text-teal" : "text-indigo-600",
                                "absolute inset-y-0 right-0 flex items-center pr-4"
                              )}>
                              <BsCheck2
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
    </div>
  );
}

export function SelectSimple({ label, options, onChange, value, className }) {
  return (
    <div className={`w-full ${className}`}>
      <select
        value={value}
        className="relative h-10 w-full cursor-pointer rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-200 focus:outline-none focus:ring-1 focus:ring-primary sm:text-sm sm:leading-6"
        onChange={(e) => onChange(e?.target?.value)}>
        <option selected>Choose {label}</option>
        {options?.map((option, index) => (
          <option value={option}>{option}</option>
        ))}
      </select>
    </div>
  );
}
