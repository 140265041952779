import Button, { IconButton } from "components/common/button";
import Loader from "components/loader";
import TierInfo from "components/tierInfo";
import { useAuthContext } from "contexts/authContext";
import Layout from "fragments/layout/layout";
import RouteGuard from "hoc/routeGuard";
import { AiFillCheckCircle } from "react-icons/ai";
import { BiError } from "react-icons/bi";
import { FaCrown } from "react-icons/fa";
import { MdDoNotDisturbOn } from "react-icons/md";
import { RxReload } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { fetchCamerasStatuses } from "store/camerasSlice";
import { fetchSprinkersStatuses } from "store/sprinklersSlice";

function Configurations() {
  const dispatch = useDispatch();
  const { userTier } = useAuthContext();
  const { camerasStatuses, loadingCamerasStatuses } = useSelector(
    (state) => state.cameras
  );
  const { sprinklersStatuses, loadingSprinklersStatuses } = useSelector(
    (state) => state.sprinklers
  );

  // console.log(userTier);

  return (
    <RouteGuard>
      <Layout title="Configurations">
        <div className="w-full flex justify-center py-16">
          <div className="max-w-[1400px] w-full flex flex-col items-center px-6 ">
            <h1 className="text-blue md:text-4xl py-6 text-2xl font-bold text-center">
              Configurations
            </h1>
            <TierInfo />
            <div className="shadow-border w-full max-w-[700px] px-6 py-6 mb-20 mt-8 rounded-lg">
              <p class="text-sm text-rose-700">
                All the components must be configured below:
              </p>
              <div className="flex sm:flex-row flex-col w-full gap-4 mt-4">
                <Link to="/configurations/allow-port" className="w-full">
                  <div className="flex gap-4 w-full justify-center items-center rounded-md font-semibold border border-blue text-blue py-4 px-6">
                    1. Allow Port
                  </div>
                </Link>
                <Link to="/configurations/camera" className="w-full">
                  <div className="flex gap-4 w-full justify-center items-center rounded-md font-semibold border border-blue text-blue py-4 px-6">
                    2. Camera
                  </div>
                </Link>
                <Link to="/configurations/sprinkle" className="w-full">
                  <div className="flex gap-4 w-full justify-center rounded-md font-semibold border border-blue text-blue py-4 px-6">
                    3. Sprinkler
                  </div>
                </Link>
                <Link to="/configurations/logic" className="w-full">
                  <div className="flex gap-4 w-full justify-center rounded-md font-semibold border border-blue text-blue py-4 px-6">
                    4. Logic
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </RouteGuard>
  );
}

export default Configurations;
