export function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export function capitalizeFirstLetter(inputString) {
  if (!inputString) {
    return inputString;
  }

  return inputString.charAt(0).toUpperCase() + inputString.slice(1);
}
