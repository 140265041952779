import { getSubscription } from "apis";
import { auth, googleAuthProvider, oAuthProvider, oAuthProviderApple } from "config/firebase";
import { OAuthProvider, signInWithPopup } from "firebase/auth";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getLocalData, removeLocalData } from "utils/storage";

const AuthContext = React.createContext(null);

/**
 * This hook can only be used by components under the `AppContext` component. Otherwise it will throw.
 */
export const useAuthContext = () => {
  const context = React.useContext(AuthContext);

  if (!context) {
    throw new Error("Component rendered outside the provider tree");
  }

  return context;
};

/**
 * Component used to render components that depend on AppContext being available. These components can then
 * `useAppContext` safely to get stuff without having to null check it.
 */
export const AuthState = (props) => {
  const navigate = useNavigate();
  const [user, setUser] = useState();
  const [token, setToken] = useState();
  const [userTier, setUserTier] = useState();

  const signinWithGoogle = () => {
    return signInWithPopup(auth, googleAuthProvider);
  };

  const signinWithMicrosoft = () => {
    return signInWithPopup(auth, oAuthProvider)
      .then((result) => {
        // User is signed in.
        // IdP data available in result.additionalUserInfo.profile.

        // Get the OAuth access token and ID Token
        // const credential = oAuthProvider.credentialFromResult(result);
        // const accessToken = credential.accessToken;
        // const idToken = credential.idToken;
        // console.log(accessToken)
        // console.log(idToken)
        return result
      })
      .catch((error) => {
        // Handle error.
        console.log(error)
        return error
      });
  };

  const signinWithApple = () => {
    return signInWithPopup(auth, oAuthProviderApple)
      .then((result) => {
        // User is signed in.
        // IdP data available in result.additionalUserInfo.profile.

        // Get the OAuth access token and ID Token
        // const credential = oAuthProvider.credentialFromResult(result);
        // const accessToken = credential.accessToken;
        // const idToken = credential.idToken;
        // console.log(accessToken)
        // console.log(result)
        return result
      })
      .catch((error) => {
        // Handle error.
        console.log(error)
        return error
      });
  };

  const checkTier = async () => {
    await getSubscription()
      .then((res) => {
        console.log(res);
        if (res?.data?.result) {
          const _tier = res?.data?.result;
          setUserTier(_tier);
        } else {
          setUserTier(undefined);
        }
      })
      .catch((err) => {
        console.log(err);
        if (err?.response?.status == 401) {
          handleSessionExpired();
        }
      });
  };

  const handleLogout = () => {
    removeLocalData("__l");
    removeLocalData("user");
    navigate("/");
    window?.location?.reload();
  };

  const handleSessionExpired = () => {
    toast.error("Session Expired. Kindly login again");
    removeLocalData("__l");
    removeLocalData("user");
    window?.location?.reload();
    // navigate("/session-expired");
  };

  useEffect(() => {
    const _user = getLocalData("user");
    const _token = getLocalData("__l");
    setUser(JSON.parse(_user));
    setToken(_token);
    if (_token) {
      checkTier();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user,
        token,
        userTier,
        setUserTier,
        handleSessionExpired,
        signinWithGoogle,
        signinWithMicrosoft,
        signinWithApple,
        handleLogout,
      }}>
      {props.children}
    </AuthContext.Provider>
  );
};
