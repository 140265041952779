import { getCardDetails, getStats } from "apis";
import { toast } from "react-toastify";

const { createSlice } = require("@reduxjs/toolkit");

const userDetailSlice = createSlice({
  name: "userDetails",
  initialState: {
    card: null,
    stats: null,
    loadingStats: false,
  },
  reducers: {
    setCardDetails(state, action) {
      state.card = action.payload;
    },
    setStats(state, action) {
      state.stats = action.payload;
    },
    // for loadings
    setStatsLoading(state, action) {
      state.loadingStats = action.payload;
    },
  },
});

export const { setCardDetails, setStatsLoading, setStats } =
  userDetailSlice.actions;
export default userDetailSlice.reducer;

// thunks
export function fetchCardDetails() {
  return async function fetchCardDetailsThunk(dispatch, getState) {
    dispatch(setCardDetails(null));
    await getCardDetails()
      .then((res) => {
        console.log(res);
        dispatch(setCardDetails(res?.data?.result[0] || "no details"));
      })
      .catch((err) => {
        console.log(err);
        toast?.error("Error Fetching CardDetails");
      });
  };
}

export function fetchStats() {
  return async function fetchStatsThunk(dispatch, getState) {
    dispatch(setStatsLoading(true));
    dispatch(setStats(null));
    await getStats()
      .then((res) => {
        console.log(res);
        dispatch(setStats(res?.data?.result || "no details"));
      })
      .catch((err) => {
        console.log(err);
        toast?.error("Error Fetching Stats");
      });
    dispatch(setStatsLoading(false));
  };
}
