import axios from "axios";
import { getLocalData } from "utils/storage";

// const api_url = process.env.REACT_APP_API_URL;
// const api_url = "http://192.168.246.19:8000/v1";
const api_url = "https://api.homehalo.ai/v1";

let defaultHeaders = {
  Accept: "application/json",
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
  "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
};

let authHeaders = {
  Accept: "application/json",
  "Access-Control-Allow-Origin": "*",
  Authorization: `Bearer ${getLocalData("__l")}`,
};

// #############
// auth
// #############
export const login = ({ username, password, user_uuid }) => {
  return axios.post(`${api_url}/token`, {
    username,
    password,
    user_uuid,
  });
};

export const socialLoginApi = ({ username, email, uId, type }) => {
  return axios.post(`${api_url}/user/auth`, {
    username,
    email,
    uId,
    type,
  });
};

export const changeUserSurveyStatus = () => {
  return axios.post(
    `${api_url}/user/serveyStatus`,
    {},
    {
      headers: authHeaders,
    }
  );
};

// #############
// sprinklers
// #############
export const getSprinklerStatuses = () => {
  return axios.get(`${api_url}/sprinkler_config/statuses`, {
    headers: authHeaders,
  });
};

export const getSprinklerConfigs = () => {
  return axios.get(`${api_url}/sprinkler_config/`, { headers: authHeaders });
};

export const addSprinklerConfig = ({ configs }) => {
  return axios.post(
    `${api_url}/sprinkler_config/`,
    { configs: configs },
    { headers: authHeaders }
  );
};

// #############
// cameras
// #############
export const getCameraStatuses = () => {
  return axios.get(`${api_url}/camera_config/statuses`, {
    headers: authHeaders,
  });
};

export const getCameraConfigs = () => {
  return axios.get(`${api_url}/camera_config/`, { headers: authHeaders });
};

export const addCameraConfig = ({ configs }) => {
  return axios.post(
    `${api_url}/camera_config/`,
    { configs: configs },
    { headers: authHeaders }
  );
};

// #############
// logic
// #############
export const getLogicConfig = () => {
  return axios.get(`${api_url}/logic_config/`, { headers: authHeaders });
};

export const addLogicConfig = ({ logicConfig }) => {
  console.log(logicConfig);
  return axios.post(
    `${api_url}/logic_config/`,
    { ...logicConfig },
    { headers: authHeaders }
  );
};

export const getLogicConfigValideValues = () => {
  return axios.post(
    `${api_url}/v1/set_logic_config/?schema=true`,
    {},
    { headers: authHeaders }
  );
};

// #############
// subscriptions | Card details
// #############
export const getSubscription = () => {
  return axios.get(`${api_url}/user/subscription`, { headers: authHeaders });
};

export const cancelSubscription = () => {
  return axios.post(
    `${api_url}/user/cancelSubscription`,
    {},
    {
      headers: authHeaders,
    }
  );
};

export const startTrial = () => {
  return axios.post(
    `${api_url}/user/start_trial`,
    {},
    {
      headers: authHeaders,
    }
  );
};

export const getCardDetails = () => {
  return axios.get(`${api_url}/user/card`, { headers: authHeaders });
};

export const updateCardDetails = ({ cardToken }) => {
  return axios.post(
    `${api_url}/user/card`,
    { card_token: cardToken },
    { headers: authHeaders }
  );
};

export const deleteCardDetails = ({ cardToken }) => {
  return axios.delete(`${api_url}/user/card/${cardToken}`, {
    headers: authHeaders,
  });
};

export const getStats = () => {
  return axios.get(`${api_url}/user/stats`, { headers: authHeaders });
};

export const getTrialStatus = () => {
  return axios.get(`${api_url}/user/trialStatus`, { headers: authHeaders });
};
