import Button from "components/common/button";
import { YoutubeEmbed } from "components/youtubeEmbed";
import { Link } from "react-router-dom";

function Hero() {
  return (
    <div className="relative overflow-hidden">
      <main className="mx-auto mt-16 max-w-7xl px-4 sm:mt-24 lg:mt-24">
        <div className="lg:grid lg:grid-cols-12 lg:gap-8">
          <div className="sm:text-center md:mx-auto md:max-w-2xl col-span-5 xl:col-span-6 lg:text-left">
            <h1>
              <span className="mt-1 block text-4xl font-bold tracking-tight sm:text-5xl xl:text-6xl">
                  Keep those <span className="text-indigo-600">deer</span> out of your <span className="text-indigo-600">yard</span>
              </span>
            </h1>
            <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
              Eco-friendly deer deterrence made simple using your sprinklers and
              cameras! No addtional equipment required !
            </p>
            <div className="mt-8 sm:mx-auto sm:max-w-lg sm:text-center lg:mx-0 lg:text-left">
              <p className="text-base font-medium text-gray-900">
               Kick off your 14-day trial
              </p>

              <Link to="/join-waitlist">
                <Button
                  type="submit"
                  className="mt-3 w-full rounded-md border border-transparent bg-blue-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-gray-900">
                  Get Started Today
                </Button>
              </Link>
            </div>
          </div>
          <div className="relative overflow-hidden text- mt-12 sm:mx-auto sm:max-w-l col-span-7 xl:col-span-6 lg:mx-0 lg:mt-0 lg:flex lg:max-w-none lg:items-center">
            <div className="relative mx-auto flex justify-center w-full lg:max-w-[700px]">
              <div className="relative sm:block hidden rounded-lg overflow-hidden">
                <YoutubeEmbed
                  className="bottom-0 lg:bottom-[80px]"
                  embedId={"kQp2r9ZEIgw?si=fDCrddftrbzHWwON"}
                  width="600"
                  height="400"
                />
              </div>
              <div className="block sm:hidden lg:hidden rounded-lg overflow-hidden">
                <YoutubeEmbed
                  embedId={"kQp2r9ZEIgw?si=fDCrddftrbzHWwON"}
                  width="340"
                  height="230"
                />
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Hero;
