import Layout from "fragments/layout/layout";
import { BiUserCircle } from "react-icons/bi";
import { classNames } from "utils";
import BankDetails from "./tabs/bankDetails";
import General from "./tabs/general";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { AiFillCreditCard } from "react-icons/ai";
import { MdOutlineSubscriptions } from "react-icons/md";
import RouteGuard from "hoc/routeGuard";
import { getCardDetails } from "apis";
import { useAuthContext } from "contexts/authContext";

const secondaryNavigation = [
  {
    name: "Account",
    to: "/settings?tab=account",
    icon: BiUserCircle,
    current: true,
  },
  // {
  //   name: "Plan",
  //   to: "/settings?tab=plan",
  //   icon: MdOutlineSubscriptions,
  //   current: false,
  // },
  {
    name: "Subscription",
    to: "/settings?tab=subscription",
    icon: AiFillCreditCard,
    current: false,
  },
];

function Settings() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const _tab = searchParams.get("tab");

  useEffect(() => {
    if (!_tab) {
      navigate("/settings?tab=account");
    }
  }, []);

  return (
    <RouteGuard>
      <Layout title="Settings">
        <div className="mx-auto max-w-7xl pb-24 lg:flex lg:gap-x-16 lg:px-8">
          <aside className="flex overflow-x-auto border-b border-gray-900/5 lg:block lg:w-64 lg:flex-none lg:border-0 py-8">
            <nav className="flex-none px-4 sm:px-6 lg:px-0">
              <ul className="flex gap-x-3 gap-y-1 whitespace-nowrap lg:flex-col">
                {secondaryNavigation.map((item) => (
                  <li key={item.name}>
                    <Link
                      to={item.to}
                      className={classNames(
                        _tab == item?.name.toLowerCase()
                          ? "bg-blue-100 text-primary"
                          : "text-gray-700 hover:text-primary hover:bg-gray-100",
                        "group flex gap-x-3 rounded-md py-2 pl-2 pr-3 text-sm leading-6 font-semibold"
                      )}>
                      <item.icon
                        className={classNames(
                          _tab == item?.name.toLowerCase()
                            ? "text-primary"
                            : "text-gray-400 group-hover:text-primary",
                          "h-6 w-6 shrink-0"
                        )}
                        aria-hidden="true"
                      />
                      {item.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </nav>
          </aside>

          <main className="px-4 py-8 sm:px-6 lg:flex-auto lg:px-0">
            <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">
              {_tab == "account" && <General />}

              {_tab == "subscription" && <BankDetails />}

              {_tab == "plan" && <p>Coming Soon</p>}
            </div>
          </main>
        </div>
      </Layout>
    </RouteGuard>
  );
}

export default Settings;
