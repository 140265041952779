import Button from "components/common/button";
import Layout from "fragments/layout/layout";
import { useState } from "react";
import { BiCheck } from "react-icons/bi";

import { getTrialStatus } from "apis";
import {
  stripLinkForBasicPlanWithTrial,
  stripLinkForBasicPlanWithoutTrial,
  stripLinkForPremiumPlanWithTrial,
  stripLinkForPremiumPlanWithoutTrial,
} from "constants";
import { tiers } from "constants/tiers";
import { useAuthContext } from "contexts/authContext";
import { useModalsContext } from "contexts/modalContext";
import { toast } from "react-toastify";
import { classNames } from "utils";

const frequencies = [
  { value: "monthly", label: "Monthly", priceSuffix: "/month" },
  { value: "annually", label: "Annually", priceSuffix: "/year" },
];

export default function Plans() {
  const [frequency, setFrequency] = useState(frequencies[0]);
  const { userTier, token, user, handleSessionExpired } = useAuthContext();
  const [buying, setBuying] = useState();
  const { toggleCancelSubscriptionModal, toggleLoginModal } =
    useModalsContext();

  const handleBuyPlan = async (planName) => {
    setBuying(planName);
    await getTrialStatus()
      .then((res) => {
        // console.log(res);
        const trialTaken = res?.data?.result;

        if (userTier?.tier == "basic" || userTier?.tier == "premium") {
          toggleCancelSubscriptionModal({
            heading: "Do want cancel current plan to buy new plan?",
          });
        } else {
          if (planName == "basic") {
            if (!trialTaken) {
              window.location.assign(
                `${stripLinkForBasicPlanWithTrial}${user?.email}`
              );
            } else {
              window.location.assign(
                `${stripLinkForBasicPlanWithoutTrial}${user?.email}`
              );
            }
          } else if (planName == "premium") {
            if (!trialTaken) {
              window.location.assign(
                `${stripLinkForPremiumPlanWithTrial}${user?.email}`
              );
            } else {
              window.location.assign(
                `${stripLinkForPremiumPlanWithoutTrial}${user?.email}`
              );
            }
          }
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(
          err?.response?.data?.message || "Error Checking for payment."
        );

        // handleSessionExpired();
      });
    setBuying(false);
  };

  return (
    <Layout title="Services">
      <div className="pb-24 pt-16 mb-24">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-4xl text-center">
            <h2 className="text-base font-semibold leading-7 text-primary">
              Pricing
            </h2>
            <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
              Backyard Protection Pricing Options
            </p>
          </div>
          <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600">
            Select the ideal coverage plan for your backyard, keeping in mind
            the significant investment of both time and effort dedicated to
            nurturing those precious plants.
          </p>
          <p className="mx-auto mt-6 max-w-2xl text-center text-sm text-rose-700">
            Compatibility: Our current support is limited to RTSP cameras, and
            we exclusively support smart sprinkler controllers from Rachio.
          </p>
          <div className="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
            {tiers?.map((tier) => (
              <div
                key={tier?.id}
                className={classNames(
                  tier?.mostPopular
                    ? "ring-2 ring-primary bg-blue text-white transform scale-[1.1]"
                    : "ring-1 ring-gray-200",
                  "rounded-3xl p-8 xl:p-10 mt-8"
                )}>
                {tier?.backendName == userTier?.tier && (
                  <div className="flex items-center justify-between relative bottom-5">
                    <p className="text-primary font-bold text-sm">
                      Current Plan
                    </p>
                    <p className="text-xl text-primary font-bold">
                      {userTier?.days} Days Left
                    </p>
                  </div>
                )}
                <div className="flex items-center justify-between gap-x-4">
                  <h3
                    id={tier?.id}
                    className={classNames(
                      tier?.mostPopular ? "text-primary" : "text-gray-900",
                      "text-lg font-semibold leading-8"
                    )}>
                    {tier?.name}
                  </h3>
                  {tier?.mostPopular ? (
                    <p className="rounded-full bg-primary px-2.5 py-1 text-xs font-semibold leading-5 text-blue">
                      Most popular
                    </p>
                  ) : null}
                </div>
                <p className="mt-4 text-sm leading-6 text-gray-600">
                  {tier?.description}
                </p>
                <p className="mt-6 flex items-baseline gap-x-1">
                  <span className="text-4xl font-bold tracking-tight text-gray-900">
                    {tier?.price[frequency?.value]}
                  </span>
                  {tier?.name == "Basic" && (
                    <span className="text-sm font-semibold leading-6 text-primary">
                      {frequency?.priceSuffix}
                    </span>
                  )}
                  {tier?.name == "Premium" && (
                    <span className="text-sm font-semibold leading-6 text-primary">
                      {frequency?.priceSuffix}
                    </span>
                  )}
                </p>
                {token ? (
                  <>
                    {userTier?.tier != tier?.backendName ? (
                      <>
                        {tier?.backendName == "basic" && (
                          <div onClick={() => handleBuyPlan("basic")}>
                            <Button
                              loading={buying == "basic"}
                              aria-describedby={tier.id}
                              className={classNames(
                                tier.mostPopular
                                  ? "bg-primary text-white shadow-sm hover:bg-primary"
                                  : "bg-white text-primary ring-1 ring-inset ring-indigo-200 hover:ring-indigo-300",
                                "mt-6 block w-full py-2 px-3 text-center text-sm font-semibold"
                              )}>
                              Buy plan
                            </Button>
                          </div>
                        )}
                        {tier?.backendName == "premium" && (
                          <div onClick={() => handleBuyPlan("premium")}>
                            <Button
                              loading={buying == "premium"}
                              aria-describedby={tier.id}
                              className={classNames(
                                tier.mostPopular
                                  ? "bg-primary text-white shadow-sm hover:bg-primary"
                                  : "bg-white text-primary ring-1 ring-inset ring-indigo-200 hover:ring-indigo-300",
                                "mt-6 block w-full py-2 px-3 text-center text-sm font-semibold"
                              )}>
                              Buy plan
                            </Button>
                          </div>
                        )}
                      </>
                    ) : (
                      <Button
                        onClick={() =>
                          toggleCancelSubscriptionModal({
                            heading:
                              "Do you want to cancel current subscription?",
                          })
                        }
                        aria-describedby={tier.id}
                        className={
                          "bg-red text-white shadow-sm hover:bg-red mt-6 block w-full py-2 px-3 text-center text-sm font-semibold"
                        }>
                        Cancel Plan
                      </Button>
                    )}
                  </>
                ) : (
                  // <Link to={`/login`}>
                  <Button
                    onClick={() => toggleLoginModal()}
                    aria-describedby={tier.id}
                    className={classNames(
                      tier.mostPopular
                        ? "bg-primary text-white shadow-sm hover:bg-primary"
                        : "bg-white text-primary ring-1 ring-inset ring-indigo-200 hover:ring-indigo-300",
                      "mt-6 block w-full py-2 px-3 text-center text-sm font-semibold"
                    )}>
                    Buy plan
                  </Button>
                  // </Link>
                )}
                <ul
                  role="list"
                  className="mt-8 space-y-3 text-sm leading-6 text-gray-600 xl:mt-10">
                  {tier.features.map((feature) => (
                    <li key={feature} className="flex gap-x-3">
                      <BiCheck
                        className="h-6 w-5 flex-none text-primary"
                        aria-hidden="true"
                      />
                      {feature?.includes("14-day") ? (
                        <p className="text-primary">{feature}</p>
                      ) : (
                        <>{feature}</>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
}
