import { Analytics } from "@vercel/analytics/react";
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// pages
import { useAuthContext } from "contexts/authContext";
import NotFound from "pages/404";
import Contact from "pages/contactus";
import Dashboard from "pages/dashboard";
import Camera from "pages/configurations/camera";
import Logic from "pages/configurations/logic";
import Sprinkle from "pages/configurations/sprinkle";
import FAQs from "pages/faqs";
import Home from "pages/home";
import Plans from "pages/plans";
import PaymentSuccess from "pages/response pages/paymentSuccess";
import SessionExpired from "pages/response pages/sessionExpired";
import WaitList from "pages/waitlist";
import { useEffect } from "react";
import { Provider, useDispatch } from "react-redux";
import { fetchCamerasStatuses, fetchCameras } from "store/camerasSlice";
import { fetchSprinkersStatuses, fetchSprinkers } from "store/sprinklersSlice";
import store from "store/store";
import ScrollToTop from "utils/scrollToTop";
import { fetchLogic } from "store/logicSlice";
import { Modals } from "contexts/modalContext";
import Settings from "pages/settings";
import Configurations from "pages/configurations";
import AllowPort from "pages/configurations/allowPort";
import { initialize, pageview } from "react-ga";
import mixpanel from "mixpanel-browser";

export default function App() {
  const dispatch = useDispatch();
  const { token } = useAuthContext();
  initialize("UA-287457297-1");
  mixpanel.init("bf601aea7d1096e66b697c5b785a5142", {
    debug: true,
    track_pageview: true,
    persistence: "localStorage",
  });

  useEffect(() => {
    pageview(window.location.pathname + window.location.search);
  }, []);

  useEffect(() => {
    if (token) {
      Promise.all([
        dispatch(fetchCameras()),
        dispatch(fetchCamerasStatuses()),
        dispatch(fetchSprinkers()),
        dispatch(fetchSprinkersStatuses()),
        dispatch(fetchLogic()),
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return (
    <Modals>
      <Provider store={store}>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          {/* <Route path="/login" element={<Login />} /> */}
          <Route path="/faqs" element={<FAQs />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/join-waitlist" element={<WaitList />} />

          <Route path="/payment-success" element={<PaymentSuccess />} />
          <Route path="/session-expired" element={<SessionExpired />} />
          {/* <Route path="/payment-fail" element={<PaymentSuccess />} /> */}

          <Route path="/settings" element={<Settings />} />
          <Route path="/configurations" element={<Configurations />} />
          <Route path="/configurations/allow-port" element={<AllowPort />} />
          <Route path="/configurations/camera" element={<Camera />} />
          <Route path="/configurations/sprinkle" element={<Sprinkle />} />
          <Route path="/configurations/logic" element={<Logic />} />

          <Route path="/plans" element={<Plans />} />

          <Route path="/dashboard" element={<Dashboard />} />

          <Route path="*" element={<NotFound />} />
        </Routes>
        <ToastContainer hideProgressBar="true" />
        <Analytics />
      </Provider>
    </Modals>
  );
}
