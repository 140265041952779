import { Dialog, Transition } from "@headlessui/react";
import { loginWithGoogleApi, socialLoginApi, startTrial } from "apis";
import { useAuthContext } from "contexts/authContext";
import { Fragment } from "react";
import { BiLogoMicrosoft } from "react-icons/bi";
import { FcGoogle } from "react-icons/fc";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { removeLocalData, setLocalData } from "utils/storage";
import { event } from "react-ga";

export default function Login({ open, handleClose }) {
  const { signinWithGoogle, user, signinWithMicrosoft, signinWithApple } =
    useAuthContext();
  const navigate = useNavigate();

  const handleSocialLoginApi = async ({ user, type }) => {
    socialLoginApi({
      email: user?.email,
      uId: user?.uid,
      username: user?.displayName,
      type: type,
    })
      .then((res) => {
        // console.log(res);
        setLocalData("__l", res?.data?.result?.token);
        setLocalData(
          "user",
          JSON.stringify({
            email: user?.email,
            uId: user?.uid,
            username: user?.displayName,
            type: type,
            photoURL: user?.photoURL,
            survey_done: res?.data?.result?.user?.survey_done,
          })
        );

        if (res?.data?.result?.user?.survey_done) {
        } else {
          navigate("/join-waitlist");
        }
        event({
          category: "User",
          action: "User Logged",
        });
        window.location.reload();
        // handleStartTrial({ token: res?.data?.result?.token });
      })
      .catch((error) => {
        toast.error("Error login");
        removeLocalData("__l");
        removeLocalData("user");
      });
  };

  // const handleStartTrial = async ({ token }) => {
  //   await startTrial({ token })
  //     .then((res) => {
  //       console.log(res);
  //       window.location.reload();
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  const handleLoginWithGoogle = async () => {
    await signinWithGoogle()
      .then((response) => {
        // console.log(response.user);
        handleSocialLoginApi({ user: response?.user, type:"google" });
      })
      .catch((error) => {
        console.error(error);
        toast.error("Error login");
      });
  };

  // const handleLoginWithMicrosoft = async () => {
  //   await signinWithMicrosoft()
  //     .then((response) => {
  //       console.log(response.code);
  //       toast.error(response?.code || "Error login");
  //       // handleGoogleApi({ user: response?.user });
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //       toast.error("Error login");
  //     });
  // };

  const handleLoginWithApple = async () => {
    await signinWithApple()
      .then((response) => {
        console.log(response);
        handleSocialLoginApi({ user: response?.user, type: 'apple' });
      })
      .catch((error) => {
        console.error(error);
        toast.error("Error login");
      });
    // toast?.info("Coming soon.");
    // handleClose();
  };

  return (
    <Transition show={open ? true : false} as={Fragment}>
      <Dialog as="div" className="relative z-[9999]" onClose={handleClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95">
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <h3 className="text-3xl font-semibold text-center">Login</h3>
                <div className="mt-6 flex flex-col gap-6">
                  <div
                    onClick={() => handleLoginWithGoogle()}
                    className="flex w-full items-center cursor-pointer justify-center gap-3 rounded-md p-3 text-whit bg-white text-sm shadow-1">
                    <FcGoogle className="text-2xl" />
                    <span className="font-semibold leading-6">
                      Login With Google
                    </span>
                  </div>
                  {/* <div
                    onClick={() => handleLoginWithMicrosoft()}
                    className="flex w-full items-center cursor-pointer justify-center gap-3 rounded-md p-3 text-white bg-blue-800 text-sm shadow-1">
                    <BiLogoMicrosoft className="text-2xl" />
                    <span className="font-semibold leading-6">Microsoft</span>
                  </div> */}
                  <div
                    onClick={() => handleLoginWithApple()}
                    className="flex w-full items-center cursor-pointer justify-center gap-3 rounded-md p-3 text-white bg-black text-sm shadow-1">
                    <svg
                      class="w-6 h-6"
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fab"
                      data-icon="apple"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 384 512">
                      <path
                        fill="currentColor"
                        d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z"></path>
                    </svg>
                    <span className="font-semibold leading-6">
                      Sign in with Apple
                    </span>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
