import { Dialog, Transition } from "@headlessui/react";
import { Elements, ElementsConsumer } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { deleteCardDetails } from "apis";
import Button from "components/common/button";
import { TextField } from "components/common/fields";
import { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { fetchCardDetails } from "store/userDetailSlice";

const stripePromise = loadStripe(
  "pk_test_51No6tHAzXDAaFJyBWS83AsaFERmMFeLofZseD9GOORy2vJNccQJuLyzInyAhbNPCL0XSoaWExtMomwqtAKgI1Vct00mtsBs8aB"
);

export default function DeleteCardModal({ open, handleClose }) {
  const dispatch = useDispatch();
  const [deleting, setDeleting] = useState(false);
  const { card } = useSelector((state) => state.cardDetails);

  const handleDeleteCard = async () => {
    setDeleting(true);
    await deleteCardDetails({ cardToken: card?.id })
      .then((res) => {
        // console.log(res);
        dispatch(fetchCardDetails());
        handleClose();
        toast?.success("Successfully Deleted Card Details.")
      })
      .catch((err) => {
        // console.log(err);
        toast?.error(
          err.response?.data?.message || "Error Deleting Card Details."
        );
      });
    setDeleting(false);
  };

  return (
    <Elements stripe={stripePromise}>
      <ElementsConsumer>
        {({ stripe, elements }) => (
          <Transition appear show={open ? true : false} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={() => {}}>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0">
                <div className="fixed inset-0 bg-black bg-opacity-25" />
              </Transition.Child>

              <div className="fixed inset-0 overflow-y-auto">
                <div className="flex min-h-full items-center justify-center p-4 text-center">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95">
                    <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                      <h3 className="text-2xl font-semibold text-center">
                        Remove Card Details
                      </h3>
                      <div>
                        <div className="mt-6">
                          <div className="flex gap-4 flex-col mb-4">
                            <TextField
                              label="Card Number"
                              disabled
                              value={card?.number}
                              className="w-full opacity-60"
                            />
                            <div className="flex gap-4 w-full">
                              <TextField
                                label="Card Expiry"
                                disabled
                                className="w-full md:w-fit opacity-60"
                                value={`${card?.exp_month}/${card?.exp_year}`}
                              />
                              <TextField
                                label="CVC"
                                disabled
                                value="***"
                                className="w-full md:w-fit opacity-60"
                              />
                            </div>
                          </div>
                          <div className="flex w-full gap-2 justify-end">
                            <Button
                              onClick={() => handleClose()}
                              className="bg-white text-primary">
                              Cancel
                            </Button>
                            <Button
                              onClick={() => handleDeleteCard()}
                              loading={deleting}
                              className="text-white bg-red">
                              Remove Card
                            </Button>
                          </div>
                        </div>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition>
        )}
      </ElementsConsumer>
    </Elements>
  );
}
