import { useId } from "react";
import { BsInfo, BsInfoCircle } from "react-icons/bs";
import Tooltip from "../tooltip";

const formClasses =
  "block text-blue w-full appearance-none rounded-md border border-darkgray bg-inherit px-3 py-2 text-blue placeholder-darkgray focus:border-blue focus:bg-light focus:outline-1 outline-blue outline-2 focus:ring-blue sm:text-sm";

function Label({ id, children, className }) {
  return (
    <label
      htmlFor={id}
      className={`mb-1 block text-sm font-medium text-blue ${className}`}>
      {children}
    </label>
  );
}

export function TextField({
  label,
  type = "text",
  className,
  error,
  value,
  onChange,
  tooltipText,
  ...props
}) {
  let id = useId();

  return (
    <div className={className}>
      {label && (
        <Label
          id={id}
          className={`focus:text-primary flex items-center gap-1  ${
            error && "text-red"
          }`}>
          {label}
          {tooltipText && (
            <Tooltip content={tooltipText}>
              <BsInfoCircle
                className={`cursor-pointer opacity-40 ${error && "text-red"}`}
              />
            </Tooltip>
          )}
        </Label>
      )}
      <div>
        <input
          id={id}
          type={type}
          {...props}
          value={value}
          aria-describedby="price-currency"
          onChange={onChange}
          className={`${formClasses} border border-lightgray focus:outline-primary ${
            error && "text-red border-red outline-red"
          }`}
        />
        {/* <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
          <span className="text-gray-500 sm:text-sm" id="price-currency">
            USD
          </span>
        </div> */}
      </div>
      {error && (
        <p className="text-[12px] opacity-[0.7] text-red">
          something went wrong
        </p>
      )}
    </div>
  );
}

export function SelectField({ label, className, ...props }) {
  let id = useId();

  return (
    <div className={className}>
      {label && <Label id={id}>{label}</Label>}
      <select id={id} {...props} className={`${formClasses} pr-8`} />
    </div>
  );
}
