// import { EnvelopeIcon, PhoneIcon } from '@heroicons/react/24/outline'

import Button from "components/common/button";
import { TextField } from "components/common/fields";
import Layout from "fragments/layout/layout";
import { useState } from "react";
import emailjs from "@emailjs/browser";
import { toast } from "react-toastify";

const serviceKey = process.env.REACT_APP_EMAILJS_SERVICE_CODE;
const templateKey = process.env.REACT_APP_EMAILJS_TEMPLATE_KEY;
const publicKey = process.env.REACT_APP_EMAILJS_PUBLIC_KEY;

export default function Contact() {
  const [sending, setSending] = useState(false);
  const [data, setData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const sendMail = async (e) => {
    e.preventDefault();
    setSending(true);
    await emailjs
      .send(
        serviceKey,
        templateKey,
        {
          from_name: data?.name,
          from_email: data?.email,
          message: data?.message,
        },
        publicKey
      )
      .then((res) => {
        console.log(res);
        toast.success("Successfully Submit.");
        setData({
          name: "",
          email: "",
          message: "",
        });
      })
      .catch((err) => {
        console.log(err);
        toast.success("Error Submiting.");
      });
    setSending(false);
  };

  return (
    <Layout title="Contact us">
      <div className="relative bg-wte py-12">
        <div className="mx-auto max-w-4xl text-center">
          <p className="pb-16 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
            Contact us
          </p>
        </div>
        <div className="relative mx-auto max-w-7xl lg:grid lg:grid-cols-5 gap-x-4">
          <div className="bg-gray-50 rounded-xl p-6 lg:col-span-2 ">
            <div className="mx-auto max-w-lg">
              <h2 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">
                Get in touch
              </h2>
              <p className="mt-3 text-lg leading-6 text-gray-500">
                If you have feedback or need a customized solution for your
                facility, please reach out to us. We'd love to hear from you and
                are just a message away.
              </p>
              <br />
              <br />
              <div className="flex justify-center items-center">
                <img
                  src="https://cdn-icons-png.flaticon.com/512/1063/1063448.png?ga=GA1.1.510250174.1695603180"
                  alt="hello deer"
                  className="max-w-[15rem]"
                />
              </div>
            </div>
          </div>
          <div className="bg-white shadow-1 rounded-xl p-8 lg:col-span-3 h-fit">
            <div className="mx-auto max-w-lg lg:max-w-none">
              <form onSubmit={sendMail} className="grid grid-cols-1 gap-y-6">
                <TextField
                  value={data?.name}
                  label="Full name"
                  required
                  onChange={(e) => setData({ ...data, name: e?.target?.value })}
                />
                <TextField
                  value={data?.email}
                  label="Email"
                  required
                  type="email"
                  onChange={(e) =>
                    setData({ ...data, email: e?.target?.value })
                  }
                />
                <div>
                  <label
                    htmlFor="message"
                    className="mb-1 block text-sm font-medium text-blue">
                    Message
                  </label>
                  <textarea
                    required
                    value={data?.message}
                    id="message"
                    name="message"
                    rows={6}
                    onChange={(e) =>
                      setData({ ...data, message: e?.target?.value })
                    }
                    className="block w-full px-3 py-2 border border-lightgray rounded-md focus:outline-primary"
                  />
                </div>
                <div>
                  <Button
                    loading={sending}
                    type="submit"
                    className="bg-blue w-full text-white">
                    Submit
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
