import { Container } from "components/common/container";
import Layout from "fragments/layout/layout";

const faqs = [
  [
    {
      question: "Which sprinkler systems do you support?",
      answer: "Currently, we support Rachio. In the future, we plan to integrate RainBird and Orbit B-hyve.",
    },
    {
      question: "How many zones does HomeHalo support?",
      answer: "There is no maximum limit to the number of zones that can be added.",
    },
    {
      question: "I don't have a smart sprinkler system, what can I do?",
      answer: "If your current sprinkler system isn't smart-enabled, you can easily purchase a smart sprinkler controller from your local HomeDepot or Lowes to make it compatible with HomeHalo.",
    },
    {
      question: "I don't have a sprinkler system, what can I do?",
      answer: "No worries, we've got you covered. Rachio offers a WiFi-enabled product for your hose. You can attach this smart device to your hose, effectively turning it into a smart sprinkler system. When an animal approaches your plants, it will activate, functioning just like a regular sprinkler system.",
    },
  ],
  [
    {
      question: "I don't have a security camera system. Which one do you recommend?",
      answer: "We recommend opting for camera systems like eufy, wyze, reolink, or any other that supports RSTP (Real-Time Streaming Protocol).",
    },
    {
      question: "Does HomeHalo work with Ring or Blink camera systems?",
      answer: "Unfortunately, HomeHalo is not compatible with Ring or Blink systems as they do not utilize RSTP.",
    },
    {
      question: "Can I use multiple cameras?",
      answer: "Yes, you can use as many cameras as you need. We recommend having at least one camera in the front yard and one in the back to ensure comprehensive protection for all your plants.",
    },
  ],
  [
    {
      question: "Does HomeHalo detect any animals besides deer?",
      answer: "Absolutely. Currently, we support detection for deer, rabbits, and groundhogs. However, we can enable detection for any specific animal or object. Please send us an email for further information.",
    },
    {
      question: "My camera system already has animal detection, why do I need HomeHalo?",
      answer: "While many camera systems offer object detection, HomeHalo goes beyond by not only detecting but also adding intelligent automation to your home. It activates your sprinkler system to deter the detected animals effectively.",
    },
    {
      question: "Will the sprinkler turn on if a person is detected?",
      answer: "No, HomeHalo is designed to differentiate between humans and animals.",
    },
  ],
];

export default function FAQs() {
  return (
    <Layout title="Faqs">
      <section
        id="faq"
        aria-labelledby="faq-title"
        className="relative overflow-hidden py-20">
        <Container className="relative">
          <div className="mx-2 pb-12 ">
            <div className="mx-auto max-w-4xl text-center">
              
              <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                Frequently Asked Questions
              </p>
            </div>
          </div>
          <ul className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-8 lg:max-w-none lg:grid-cols-3">
            {faqs.map((column, columnIndex) => (
              <li key={columnIndex}>
                <ul className="flex flex-col gap-y-8">
                  {column.map((faq, faqIndex) => (
                    <li key={faqIndex}>
                      <h3 className="font-display text-lg font-semibold leading-7 text-slate-900">
                        {faq.question}
                      </h3>
                      <p className="mt-4 text-md opacity-[0.6]">{faq.answer}</p>
                    </li>
                  ))}
                </ul>
              </li>
            ))}
          </ul>
        </Container>
      </section>
    </Layout>
  );
}
