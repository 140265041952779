import React, { useState } from "react";

function Tooltip({ children, content }) {
  const [show, setShow] = useState(false);

  return (
    <div
      class="group flex relative"
      onMouseOver={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
      >
      {show && (
        <span
          style={{minWidth: content?.length*3}}
          className={`absolute bottom-5 bg-white text-gray-500 shadow-border px-2 text-[12px] rounded-md`}>
          {content}
        </span>
      )}
      {children}
    </div>
  );
}

export default Tooltip;
