import { addLogicConfig } from "apis";
import Button from "components/common/button";
import { TextField } from "components/common/fields";
import { SelectSimple } from "components/common/select";
import Loader from "components/loader";
import Layout from "fragments/layout/layout";
import RouteGuard from "hoc/routeGuard";
import { useEffect, useState } from "react";
import { BiError } from "react-icons/bi";
import { GrAddCircle } from "react-icons/gr";
import { MdOutlineDoNotDisturbOn } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { BsChevronLeft } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { fetchLogic, setLogic, setLogicLoading } from "store/logicSlice";
import { RxReload } from "react-icons/rx";
import { fetchCameras, setCameras } from "store/camerasSlice";
import {
  fetchSprinkersStatuses,
  setSprinklersStatuses,
} from "store/sprinklersSlice";
import { FaCrown } from "react-icons/fa";
import { useAuthContext } from "contexts/authContext";
import TierInfo from "components/tierInfo";

const objects = ["person", "deer"];
const actions = ["Send Email", "turn on sprinkler"];

function Logic() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [logicConfig, setLogicConfig] = useState({
    global_params: {
      delay: 0,
      sprinkler_duration: "",
      sprinkler_interval: "",
      model_select: "custom",
      minimum_match_box_pixels: 1000000,
    },
    instance_params: [
      {
        camera: "",
        detect_object: "",
        sprinkler_zone: "",
        confidence: 0.7,
        action: {
          email: false,
          sprinkler: false,
        },
      },
    ],
  });
  const [saving, setSaving] = useState(false);

  // from redux store
  const { logic, loadingLogic } = useSelector((state) => state.logic);
  const { sprinklersStatuses, loadingSprinklersStatuses } = useSelector(
    (state) => state.sprinklers
  );
  const { userTier } = useAuthContext();

  const { cameras, loadingCameras } = useSelector((state) => state.cameras);

  const handleSubmitLogic = async () => {
    setSaving(true);
    await addLogicConfig({
      logicConfig,
    })
      .then((res) => {
        // console.log(res);
        dispatch(setLogic(logicConfig));
        toast.success("Successfully Update Logic Config");
      })
      .catch((err) => {
        console.log(err);
        toast.error(
          err?.response?.data?.message || "Error Updating Logic Config"
        );
      });
    setSaving(false);
  };

  const HandleChangeGlobalParams = (field, e) => {
    if (e?.target?.value >= 0) {
      setLogicConfig({
        ...logicConfig,
        global_params: {
          ...logicConfig?.global_params,
          [field]: parseInt(e?.target?.value),
        },
      });
    }
  };

  const handleChangeInstanceParams = ({ fieldName, index, value }) => {
    if (fieldName == "action") {
      let _instance_params = [...logicConfig?.instance_params];

      let actionObjectToEdit = { ..._instance_params[index]?.action };
      console.log(actionObjectToEdit);
      if (value == "email") {
        actionObjectToEdit = {
          ...actionObjectToEdit,
          email: !actionObjectToEdit?.email,
        };
        _instance_params[index] = {
          ..._instance_params[index],
          action: actionObjectToEdit,
        };
      } else if (value == "sprinkler") {
        actionObjectToEdit = {
          ...actionObjectToEdit,
          sprinkler: !actionObjectToEdit?.sprinkler,
        };
        _instance_params[index] = {
          ..._instance_params[index],
          action: actionObjectToEdit,
        };
      } else if (value == "both") {
        actionObjectToEdit = {
          email: true,
          sprinkler: true,
        };
        _instance_params[index] = {
          ..._instance_params[index],
          action: actionObjectToEdit,
        };
      }

      setLogicConfig({
        global_params: { ...logicConfig?.global_params },
        instance_params: [..._instance_params],
      });
    } else {
      let _instance_params = [...logicConfig?.instance_params];

      let objectToEdit = { ..._instance_params[index] };
      objectToEdit[fieldName] = value;
      _instance_params[index] = objectToEdit;

      setLogicConfig({
        global_params: { ...logicConfig?.global_params },
        instance_params: [..._instance_params],
      });
    }
  };

  const handleRemoveInstanceParams = (index) => {
    let _instance_params = [...logicConfig?.instance_params];
    _instance_params.splice(index, 1);

    setLogicConfig({ ...logicConfig, instance_params: [..._instance_params] });
    toast.success("Successfully Removed Instance Config");
  };

  const handleRefreshLogic = async () => {
    // reset and get logic state
    dispatch(fetchLogic());
    dispatch(setLogicLoading(true));
    // reset and get camera state
    dispatch(fetchCameras());
    dispatch(setCameras(null));
    // reset and get sprinklersStatuses state
    dispatch(fetchSprinkersStatuses());
    dispatch(setSprinklersStatuses(null));
  };

  // console.log("zones", zones);
  // console.log("logics", logics);
  // console.log("cameras", cameras);
  // console.log(logicConfig?.instance_params[0]);
  // console.log(sprinklersStatuses && sprinklersStatuses[0]?.info?.zones);

  useEffect(() => {
    setLogicConfig(logic);
  }, [logic]);

  return (
    <RouteGuard>
      <Layout title="Logic">
        <div className="w-full flex justify-center pt-8">
          <div className="max-w-[1400px] w-full flex flex-col items-center px-6">
            <div className="flex max-w-[1000px] w-full">
              <div
                onClick={() => navigate("/configurations")}
                className="hover:bg-slate-200 cursor-pointer rounded-full ">
                <BsChevronLeft className="text-2xl text-black m-3" />
              </div>
            </div>
            <h1 className="text-blue md:text-4xl pb-6 text-2xl font-bold text-center">
              Logic
            </h1>
            <TierInfo />
            <div className="flex w-full justify-end max-w-[1000px]">
              <Button
                onClick={() => handleRefreshLogic()}
                loading={loadingCameras}
                className="text-primary flex items-center gap-2 bg-sky-100">
                <RxReload className="text-lg" />
                Refresh
              </Button>
            </div>
            {!loadingLogic ? (
              <div className="shadow-1 w-full max-w-[1000px] px-6 py-4 mt-4 rounded-lg">
                <h2 className="text-lg font-bold">Global Configs</h2>
                <div className="flex gap-2 w-full sm:flex-row flex-col">
                  {/* <TextField
                    label="Delay"
                    placeholder="Enter sprinkler delay in seconds"
                    value={logicConfig?.global_params?.delay}
                    type="number"
                    onChange={(e) => HandleChangeGlobalParams("delay", e)}
                    className="w-full"
                  /> */}
                  <TextField
                    placeholder="Enter sprinkler duration in seconds"
                    label="Sprinkler Duration"
                    value={logicConfig?.global_params?.sprinkler_duration}
                    type="number"
                    onChange={(e) =>
                      HandleChangeGlobalParams("sprinkler_duration", e)
                    }
                    className="w-full"
                  />
                  <TextField
                    label="Sprinkler Interval"
                    placeholder="Enter sprinkler Interval in seconds"
                    value={logicConfig?.global_params?.sprinkler_interval}
                    type="number"
                    onChange={(e) =>
                      HandleChangeGlobalParams("sprinkler_interval", e)
                    }
                    className="w-full"
                  />
                </div>
                <div className="border-b my-6 opacity-[0.4]" />

                <div className="flex justify-between items-center">
                  <h2 className="text-lg font-bold">Instance Configs</h2>
                  <GrAddCircle
                    className="text-primary opacity-70 text-2xl cursor-pointer"
                    onClick={() => {
                      setLogicConfig({
                        global_params: { ...logicConfig?.global_params },
                        instance_params: [
                          ...logicConfig?.instance_params,
                          {
                            camera: "",
                            detect_object: "",
                            sprinkler_zone: "",
                            confidence: 0.7,
                          },
                        ],
                      });
                    }}
                  />
                </div>
                {!loadingLogic &&
                !loadingCameras &&
                !loadingSprinklersStatuses ? (
                  <>
                    {logicConfig?.instance_params?.length > 0 ? (
                      <>
                        {logicConfig?.instance_params?.map((param, index) => (
                          <div className="grid sm:grid-cols-1 opacity-70 mt-6 grid-cols-1 gap-4 justify-between w-full">
                            <div className="flex flex-col gap-4">
                              <div className="flex w-full items-start lg:items-center gap-2 border rounded-xl p-2">
                                <MdOutlineDoNotDisturbOn
                                  onClick={() => handleRemoveInstanceParams()}
                                  className="text-primary relative lg:top-0 top-5 opacity-70 text-2xl cursor-pointer"
                                />
                                <div className="flex w-full lg:items-center justify-start sm:flex-row flex-col gap-2 text-xl flex-wrap">
                                  <p>When</p>
                                  <SelectSimple
                                    label="camera"
                                    placeholder="Camera"
                                    options={
                                      cameras &&
                                      cameras?.map((cam) => cam?.name)
                                    }
                                    className="max-w-[150px] bg-white"
                                    value={param?.camera}
                                    onChange={(value) =>
                                      handleChangeInstanceParams({
                                        fieldName: "camera",
                                        index,
                                        value: value,
                                      })
                                    }
                                  />
                                  <p>detects a </p>
                                  <SelectSimple
                                    label="object"
                                    options={objects}
                                    className="max-w-[150px]"
                                    value={param?.detect_object}
                                    onChange={(value) =>
                                      handleChangeInstanceParams({
                                        fieldName: "detect_object",
                                        index,
                                        value: value,
                                      })
                                    }
                                  />
                                  <p>then</p>
                                  <div>
                                    <div className="mx-2 flex gap-1 items-center">
                                      <input
                                        id="email"
                                        name="email"
                                        type="checkbox"
                                        checked={param?.action?.email}
                                        onChange={(e) => {
                                          console.log(e?.target?.value);
                                          handleChangeInstanceParams({
                                            fieldName: "action",
                                            index,
                                            value: "email",
                                          });
                                        }}
                                        className="h-4 w-4 rounded-lg border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                      />
                                      <label
                                        htmlFor="email"
                                        className="text-[14px]">
                                        Send Email
                                      </label>
                                    </div>
                                    <div className="mx-2 flex gap-1 items-center">
                                      <input
                                        id="sprinkler"
                                        name="sprinkler"
                                        type="checkbox"
                                        onChange={(e) => {
                                          handleChangeInstanceParams({
                                            fieldName: "action",
                                            index,
                                            value: "sprinkler",
                                          });
                                        }}
                                        checked={param?.action?.sprinkler}
                                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                      />
                                      <label
                                        htmlFor="sprinkler"
                                        className="text-[14px]">
                                        turn on sprinkler
                                      </label>
                                    </div>
                                  </div>
                                  {param?.action?.sprinkler && (
                                    <>
                                      <p>on</p>
                                      <SelectSimple
                                        label="Zones"
                                        options={
                                          sprinklersStatuses &&
                                          sprinklersStatuses[0]?.info?.zones
                                        }
                                        className="max-w-[150px]"
                                        value={param?.sprinkler_zone || ""}
                                        onChange={(value) =>
                                          handleChangeInstanceParams({
                                            fieldName: "sprinkler_zone",
                                            index,
                                            value: value,
                                          })
                                        }
                                      />
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </>
                    ) : (
                      <div className="text-center flex flex-col items-center w-full my-4 opacity-60">
                        <BiError style={{ fontSize: "3rem", opacity: "0.4" }} />
                        No Instance Configs found.
                      </div>
                    )}
                  </>
                ) : (
                  <Loader />
                )}
              </div>
            ) : (
              <Loader />
            )}
            {!loadingLogic && (
              <div className="w-full flex justify-between max-w-[1000px] py-4 mb-[4rem]">
                <div></div>
                <Button
                  loading={saving}
                  onClick={() => handleSubmitLogic()}
                  className="bg-blue text-white w-fit float-right">
                  Save
                </Button>
              </div>
            )}
          </div>
        </div>
      </Layout>
    </RouteGuard>
  );
}

export default Logic;
