import Button, { IconButton } from "components/common/button";
import Loader from "components/loader";
import TierInfo from "components/tierInfo";
import { useAuthContext } from "contexts/authContext";
import Layout from "fragments/layout/layout";
import RouteGuard from "hoc/routeGuard";
import { useEffect } from "react";
import { AiFillCheckCircle } from "react-icons/ai";
import { BiError } from "react-icons/bi";
import { FaCrown } from "react-icons/fa";
import { MdDoNotDisturbOn } from "react-icons/md";
import { RxReload } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { fetchCamerasStatuses } from "store/camerasSlice";
import { fetchSprinkersStatuses } from "store/sprinklersSlice";
import { fetchStats } from "store/userDetailSlice";

function Dashboard() {
  const dispatch = useDispatch();
  const { userTier, token } = useAuthContext();
  const { camerasStatuses, loadingCamerasStatuses } = useSelector(
    (state) => state.cameras
  );
  const { sprinklersStatuses, loadingSprinklersStatuses } = useSelector(
    (state) => state.sprinklers
  );
  const { stats, loadingStats } = useSelector((state) => state.userDetails);

  useEffect(() => {
    if (token) {
      dispatch(fetchStats());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  console.log(stats);

  return (
    <RouteGuard>
      <Layout title="Dashboard">
        <div className="w-full flex justify-center py-16">
          <div className="max-w-[1400px] w-full flex flex-col items-center px-6 ">
            <h1 className="text-blue md:text-4xl py-6 text-2xl font-bold text-center">
              Dashboard
            </h1>
            <TierInfo />
            <div className="shadow-border w-full max-w-[700px] px-6 py-6 mt-8 rounded-lg">
              <div className="flex justify-between items-center">
                <h2 className="text-primary text-lg font-bold pt-0">Stats</h2>
                <IconButton
                  loading={loadingStats}
                  onClick={() => dispatch(fetchStats())}>
                  <RxReload className="text-lg" />
                </IconButton>
              </div>
              {stats && !loadingStats ? (
                <>
                  {Object?.entries(stats)?.length > 0 ? (
                    // <div className="grid grid-cols-2 gap-x-3">
                    // <div className="flex sm:flex-row  flex-col w-full gap-4 mt-4 opacity-60">
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 w-full gap-4 mt-4 opacity-60">
                      {/* {Object?.entries(stats)?.map((item, index) => (
                        <div className="flex border rounded-lg py-2 px-4 sm:grid justify-between grid-cols-2 my-2 w-full max-w-[400px] items-center">
                          <h2 className="text-base font-semibold leading-7 text-gray-900">
                            {item[0]}
                          </h2>
                          <div className="text-center">
                            <p>{item[1]}</p>
                          </div>
                        </div>
                      ))} */}
                      <div className="flex border rounded-lg py-2 px-4 justify-between my-2 w-full items-center">
                        <h2 className="text-base font-semibold leading-7 text-gray-900">
                          Deer Detected
                        </h2>
                        <div className="text-center">
                          <p>{stats?.detection?.deer}</p>
                        </div>
                      </div>
                      <div className="flex border rounded-lg py-2 px-4 justify-between my-2 w-full items-center">
                        <h2 className="text-base font-semibold leading-7 text-gray-900">
                          Deer Deterred
                        </h2>
                        <div className="text-center">
                          <p>{stats?.deterred?.deer}</p>
                        </div>
                      </div>
                      <div className="flex border rounded-lg py-2 px-4 justify-between  my-2 w-full items-center">
                        <h2 className="text-basdeere font-semibold leading-7 text-gray-900">
                          People Detected
                        </h2>
                        <div className="text-center">
                          <p>{stats?.detection?.person}</p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="text-center flex flex-col items-center w-full my-4 opacity-60">
                      <BiError style={{ fontSize: "3rem", opacity: "0.4" }} />
                      No Stats found.
                    </div>
                  )}
                </>
              ) : (
                <>
                  <Loader width="30px" height="30px" />
                </>
              )}
            </div>

            <div className="shadow-border w-full max-w-[700px] px-6 py-6 mb-20 mt-8 rounded-lg">
              {/* </div> */}

              {/* <div className="border-b my-6 opacity-[0.4]" /> */}

              <div className="flex justify-between items-center">
                <h2 className="text-primary text-lg font-bold text-center">
                  Camera Status
                </h2>
                <IconButton
                  loading={loadingCamerasStatuses}
                  onClick={() => dispatch(fetchCamerasStatuses())}>
                  <RxReload className="text-lg" />
                </IconButton>
              </div>
              <a className="text-xs text-rose-700" href="/configurations">
                Configure Here
              </a>
              <p class="text-sm opacity-50">
                Once the cameras are configured, the camera status will show in
                real-time below.
              </p>
              {camerasStatuses && !loadingCamerasStatuses ? (
                <>
                  {camerasStatuses?.length > 0 ? (
                    <div className="grid grid-cols-2 gap-x-3">
                      {camerasStatuses?.map((camera, index) => (
                        <>
                          <div className="flex border rounded-lg divide-y sm:divide-y-0 sm:divide-x mt-4">
                            <div className="flex gap-4 w-full justify-center font-semibold text-blue py-4 px-6">
                              {camera?.name}
                              {camera?.thumbnail ? (
                                <AiFillCheckCircle className="text-2xl text-green-500" />
                              ) : (
                                <MdDoNotDisturbOn className="text-2xl text-red" />
                              )}
                            </div>
                          </div>
                        </>
                      ))}
                    </div>
                  ) : (
                    <div className="text-center flex flex-col items-center w-full my-4 opacity-60">
                      <BiError style={{ fontSize: "3rem", opacity: "0.4" }} />
                      No Camera Configs found.
                    </div>
                  )}
                </>
              ) : (
                <>
                  <Loader width="30px" height="30px" />
                </>
              )}

              <div className="border-b my-6 opacity-[0.4]" />
              <div className="flex justify-between items-center">
                <h2 className="text-primary text-lg font-bold text-center">
                  Sprinkler Status
                </h2>
                <IconButton
                  loading={loadingSprinklersStatuses}
                  onClick={() => dispatch(fetchSprinkersStatuses())}>
                  <RxReload className="text-lg" />
                </IconButton>
              </div>
              <a className="text-xs text-rose-700" href="/configurations">
                Configure Here
              </a>
              <p class="text-sm opacity-50">
                Once the sprinklers are configured, the sprinkler status will
                show in real-time below.
              </p>
              {sprinklersStatuses && !loadingSprinklersStatuses ? (
                <>
                  {sprinklersStatuses?.length > 0 ? (
                    <div className="grid grid-cols-2 gap-x-3">
                      <div className="flex border rounded-lg h-fit divide-y sm:divide-y-0 sm:divide-x mt-4">
                        <div className="flex gap-4 w-full justify-center font-semibold text-blue py-4 px-6">
                          Sprinkler Status
                          <>
                            {sprinklersStatuses[0]?.info?.status == "ONLINE" ? (
                              <AiFillCheckCircle className="text-2xl text-green-500" />
                            ) : (
                              <MdDoNotDisturbOn className="text-2xl text-red" />
                            )}
                          </>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="text-center flex flex-col items-center w-full my-4 opacity-60">
                      <BiError style={{ fontSize: "3rem", opacity: "0.4" }} />
                      No Sprinkler Configs found.
                    </div>
                  )}
                </>
              ) : (
                <Loader width="30px" height="30px" />
              )}
            </div>
          </div>
        </div>
      </Layout>
    </RouteGuard>
  );
}

export default Dashboard;
