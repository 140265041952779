import { Dialog, Transition } from "@headlessui/react";
import {
  CardElement,
  Elements,
  ElementsConsumer,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { updateCardDetails } from "apis";
import Button from "components/common/button";
import { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { fetchCardDetails } from "store/userDetailSlice";

const stripePromise = loadStripe(
  "pk_live_51No6tHAzXDAaFJyByqoFiPbBg647FdUWUwLLxfSiY4olFSzLtn33KEqqML8zQcCxCjDQwcPR7n7suEUIwSjQEyVd00ziyiaHoX"
);

export default function AddCardModal({ open, handleClose }) {
  const dispatch = useDispatch();
  const [adding, setAdding] = useState(false);

  const handleSubmit = async (e, elements, stripe) => {
    e.preventDefault();
    setAdding(true);
    if (!stripe || !elements) {
      return;
    }
    const card = elements.getElement(CardElement);
    try {
      const result = await stripe.createToken(card);
      if (result.error) {
        toast?.error(result.error.message || "Error updating card details");
      } else {
        await updateCardDetails({
          cardToken: result?.token?.id,
        })
          .then((res) => {
            // console.log(res);
            dispatch(fetchCardDetails());
            handleClose();
            toast?.success("Successfully Added Card Details.");
          })
          .catch((err) => {
            // console.log(err);
            toast?.error(
              err.response?.data?.message || "Error Updating Card Details."
            );
          });
      }
    } catch (error) {
      toast?.error(error.message || "Error updating card details");
    }
  };

  return (
    <Elements stripe={stripePromise}>
      <ElementsConsumer>
        {({ stripe, elements }) => (
          <Transition appear show={open ? true : false} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={() => {}}>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0">
                <div className="fixed inset-0 bg-black bg-opacity-25" />
              </Transition.Child>

              <div className="fixed inset-0 overflow-y-auto">
                <div className="flex min-h-full items-center justify-center p-4 text-center">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95">
                    <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                      <h3 className="text-2xl font-semibold text-center">
                        Add Card Details
                      </h3>
                      <div>
                        <form
                          className="mt-6"
                          onSubmit={(e) => handleSubmit(e, elements, stripe)}>
                          <div className="my-8">
                            <div className="col-span-full">
                              <label
                                htmlFor="card-number"
                                className="mb-1 block text-sm font-medium text-blue">
                                Card Details
                              </label>
                              <div className="mt-1 border border-lightgray p-3 rounded-md">
                                <CardElement />
                              </div>
                            </div>
                          </div>
                          <div className="flex w-full gap-2 justify-end">
                            <Button
                              onClick={() => handleClose()}
                              className="bg-white text-red">
                              Cancel
                            </Button>
                            <Button loading={adding} className="text-white">
                              Add Card
                            </Button>
                          </div>
                        </form>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition>
        )}
      </ElementsConsumer>
    </Elements>
  );
}
