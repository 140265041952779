import { Dialog, Transition } from "@headlessui/react";
import { cancelSubscription } from "apis";
import Button from "components/common/button";
import { useAuthContext } from "contexts/authContext";
import { Fragment, useState } from "react";
import { BiError } from "react-icons/bi";
import { toast } from "react-toastify";
import emailjs from "@emailjs/browser";

const serviceKey = process.env.REACT_APP_EMAILJS_CANCEL_SUB_SERVICE_CODE;
const cancelSubTemplateKey =
  process.env.REACT_APP_EMAILJS_CANCEL_SUB_TEMPLATE_KEY;
const publicKey = process.env.REACT_APP_EMAILJS_PUBLIC_KEY;

export default function CancelSubscriptionModal({
  open,
  handleClose,
  payload,
}) {
  const { setUserTier, userTier, user } = useAuthContext();
  const [loading, setLoading] = useState(false);

  console.log(userTier);

  const handleCancelSubscription = async () => {
    setLoading(true);
    await cancelSubscription()
      .then((res) => {
        // console.log(res);
        setUserTier({ type: "free", days: 0 });
        sendMail();
      })
      .catch((err) => {
        // console.log(err);
        toast?.error("Error canceling the subscription.");
      });
  };

  // console.log(user)

  const sendMail = async () => {
    await emailjs
      .send(
        "service_lrzt6gg",
        "template_f6hieos",
        {
          from_name: "HomeHalo.ai",
          to_name: user?.name,
          reply_to: user?.email,
          message: `You have cancelled your homehalo ${userTier?.tier} plan.`,
        },
        publicKey
      )
      .then((res) => {
        console.log(res);
        toast?.success("Successfully cancelled the subscription.");
        handleClose();
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error Cancelling plan.");
      });
    setLoading(false);
  };

  return (
    <Transition appear show={open ? true : false} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => handleClose()}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95">
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <div className="text-center flex flex-col items-center w-full my-2 opacity-80">
                  <BiError
                    style={{ fontSize: "3rem", opacity: "1", color: "red" }}
                  />
                  <h3 className="text-2xl font-semibold text-center">
                    {payload?.heading}
                  </h3>
                </div>
                <div className="flex w-full gap-2 pt-4 justify-center">
                  <Button
                    onClick={() => handleClose()}
                    className="bg-white text-red">
                    No
                  </Button>
                  <Button
                    onClick={() => handleCancelSubscription()}
                    loading={loading}
                    className="text-white">
                    Agree
                  </Button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
